import image1 from '../images/mainstore/image1.png'
import image2 from '../images/mainstore/image2.webp'
import image3 from '../images/mainstore/image3.png'
import image4 from '../images/mainstore/image4.png'
import image5 from '../images/mainstore/image5.png'
import image6 from '../images/mainstore/image6.png'
import image7 from '../images/mainstore/image7.png'
import image8 from '../images/mainstore/image8.png'
import image9 from '../images/mainstore/image9.png'
import image10 from '../images/mainstore/image10.png'
import image11 from '../images/mainstore/image11.png'
import image12 from '../images/mainstore/image12.png'
import image13 from '../images/mainstore/image13.png'
import image14 from '../images/mainstore/image14.png'
import image15 from '../images/mainstore/image15.png'
import image16 from '../images/mainstore/image16.png'
import image17 from '../images/mainstore/image17.png'
import image18 from '../images/mainstore/image18.png'
import image19 from '../images/mainstore/image19.png'
import image20 from '../images/mainstore/image20.png'
import image21 from '../images/mainstore/image21.png'
import image22 from '../images/mainstore/image22.png'
import image23 from '../images/mainstore/image23.png'
import image24 from '../images/mainstore/image24.png'
import image25 from '../images/mainstore/image25.png'
import image26 from '../images/mainstore/image26.png'
import image27 from '../images/mainstore/image27.png'
import image28 from '../images/mainstore/image28.png'
import image29 from '../images/mainstore/image29.png'
import image30 from '../images/mainstore/image30.png'
import image31 from '../images/mainstore/image31.png'
import image32 from '../images/mainstore/image32.png'
import image33 from '../images/mainstore/image33.png'
import image34 from '../images/mainstore/image34.png'
import image35 from '../images/mainstore/image35.png'
import image36 from '../images/mainstore/image36.png'
import image37 from '../images/mainstore/image37.png'
import image38 from '../images/mainstore/image38.png'
import image39 from '../images/mainstore/image39.png'
import image40 from '../images/mainstore/image40.png'
import image41 from '../images/mainstore/image41.png'
import image42 from '../images/mainstore/image42.png'
import image43 from '../images/mainstore/image43.png'
import image44 from '../images/mainstore/image44.png'
import image45 from '../images/mainstore/image45.png'
import image46 from '../images/mainstore/image46.png'
import image47 from '../images/mainstore/image47.png'
import image48 from '../images/mainstore/image48.png'
import image49 from '../images/mainstore/image49.png'
import image50 from '../images/mainstore/image50.png'
import image51 from '../images/mainstore/image51.png'
import image52 from '../images/mainstore/image52.png'
import image53 from '../images/mainstore/image53.png'
import image54 from '../images/mainstore/image54.png'
import image55 from '../images/mainstore/image55.png'
import image56 from '../images/mainstore/image56.png'
import image57 from '../images/mainstore/image57.png'
import image58 from '../images/mainstore/image58.png'
import image59 from '../images/mainstore/image59.png'
import image60 from '../images/mainstore/image60.png'
import image61 from '../images/mainstore/image61.png'
import image62 from '../images/mainstore/image62.png'
import image63 from '../images/mainstore/image63.png'
import image64 from '../images/mainstore/image64.png'
import image65 from '../images/mainstore/image65.png'
import image66 from '../images/mainstore/image66.png'
import image67 from '../images/mainstore/image67.png'
import image68 from '../images/mainstore/image68.png'
import image69 from '../images/mainstore/image69.png'
import image70 from '../images/mainstore/image70.png'
import image71 from '../images/mainstore/image71.png'
import image72 from '../images/mainstore/image72.png'
import image73 from '../images/mainstore/image73.png'
import image74 from '../images/mainstore/image74.png'
import image75 from '../images/mainstore/image75.png'
import image76 from '../images/mainstore/image76.png'
import image77 from '../images/mainstore/image77.png'
import image78 from '../images/mainstore/image78.png'
import image79 from '../images/mainstore/image79.png'
import image80 from '../images/mainstore/image80.png'
import image81 from '../images/mainstore/image81.png'
import image82 from '../images/mainstore/image82.png'
import image83 from '../images/mainstore/image83.png'
import image84 from '../images/mainstore/image84.png'
import image85 from '../images/mainstore/image85.png'
import image86 from '../images/mainstore/image86.png'
import image87 from '../images/mainstore/image87.png'
import image88 from '../images/mainstore/image88.png'
import image89 from '../images/mainstore/image89.png'
import image90 from '../images/mainstore/image90.png'
import image91 from '../images/mainstore/image91.png'
import image92 from '../images/mainstore/image92.png'
import image93 from '../images/mainstore/image93.png'
import image94 from '../images/mainstore/image94.png'
import image95 from '../images/mainstore/image95.png'
import image96 from '../images/mainstore/image96.png'
import image97 from '../images/mainstore/image97.png'
import image98 from '../images/mainstore/image98.png'
import image99 from '../images/mainstore/image99.png'
import image100 from '../images/mainstore/image100.png'
import image101 from '../images/mainstore/image101.png'
import image102 from '../images/mainstore/image102.png'
import image103 from '../images/mainstore/image103.png'
import image104 from '../images/mainstore/image104.png'
import image105 from '../images/mainstore/image105.png'
import image106 from '../images/mainstore/image106.png'
import image107 from '../images/mainstore/image107.png'
import image108 from '../images/mainstore/image108.png'
import image109 from '../images/mainstore/image109.png'
import image110 from '../images/mainstore/image110.png'
import image111 from '../images/mainstore/image111.png'
import image112 from '../images/mainstore/image112.png'
import image113 from '../images/mainstore/image113.png'
import image114 from '../images/mainstore/image114.png'
import image115 from '../images/mainstore/image115.png'
import image116 from '../images/mainstore/image116.png'
import image117 from '../images/mainstore/image117.png'
import image118 from '../images/mainstore/image118.png'
import image119 from '../images/mainstore/image119.png'
import image120 from '../images/mainstore/image120.png'
import image121 from '../images/mainstore/image121.png'
import image122 from '../images/mainstore/image122.png'
import image123 from '../images/mainstore/image123.png'
import image124 from '../images/mainstore/image124.png'
import image125 from '../images/mainstore/image125.png'
import image126 from '../images/mainstore/image126.png'
import image127 from '../images/mainstore/image127.png'
import image128 from '../images/mainstore/image128.png'
import image129 from '../images/mainstore/image129.png'
import image130 from '../images/mainstore/image130.png'
import image131 from '../images/mainstore/image131.png'
import image132 from '../images/mainstore/image132.png'
import image133 from '../images/mainstore/image133.png'
import image134 from '../images/mainstore/image134.png'
import image135 from '../images/mainstore/image135.png'
import image136 from '../images/mainstore/image136.png'
import image137 from '../images/mainstore/image137.png'
import image138 from '../images/mainstore/image138.png'
import image139 from '../images/mainstore/image139.png'
import image140 from '../images/mainstore/image140.png'
import image141 from '../images/mainstore/image141.png'
import image142 from '../images/mainstore/image142.png'
import image143 from '../images/mainstore/image143.png'
import image144 from '../images/mainstore/image144.png'
import image145 from '../images/mainstore/image145.png'
import image146 from '../images/mainstore/image146.png'
import image147 from '../images/mainstore/image147.png'
import image148 from '../images/mainstore/image148.png'
import image149 from '../images/mainstore/image149.png'
import image150 from '../images/mainstore/image150.png'
import image151 from '../images/mainstore/image151.png'
import image152 from '../images/mainstore/image152.png'
import image153 from '../images/mainstore/image153.png'
import image154 from '../images/mainstore/image154.png'
import image155 from '../images/mainstore/image155.png'
import image156 from '../images/mainstore/image156.png'
import image157 from '../images/mainstore/image157.png'
import image158 from '../images/mainstore/image158.png'
import image159 from '../images/mainstore/image159.png'
import image160 from '../images/mainstore/image160.png'
import image161 from '../images/mainstore/image161.png'
import image162 from '../images/mainstore/image162.png'
import image163 from '../images/mainstore/image163.png'
import image164 from '../images/mainstore/image164.webp'
import image165 from '../images/mainstore/image165.webp'
import image166 from '../images/mainstore/image166.webp'
import image167 from '../images/mainstore/image167.webp'
import image168 from '../images/mainstore/image168.webp'
import image169 from '../images/mainstore/image169.webp'
import image170 from '../images/mainstore/image170.webp'
import image171 from '../images/mainstore/image171.webp'
import image172 from '../images/mainstore/image172.webp'
import image173 from '../images/mainstore/image173.webp'
import image174 from '../images/mainstore/image174.webp'
import image175 from '../images/mainstore/image175.webp'
import image176 from '../images/mainstore/image176.webp'
import image177 from '../images/mainstore/image177.webp'
import image178 from '../images/mainstore/image178.webp'
import image179 from '../images/mainstore/image179.webp'
import image180 from '../images/mainstore/image180.webp'
import image181 from '../images/mainstore/image181.webp'
import image182 from '../images/mainstore/image182.webp'
import image183 from '../images/mainstore/image183.webp'
import image184 from '../images/mainstore/image184.webp'
import image185 from '../images/mainstore/image185.webp'
import image186 from '../images/mainstore/image186.webp'
import image187 from '../images/mainstore/image187.webp'
import image188 from '../images/mainstore/image188.webp'
import image189 from '../images/mainstore/image189.webp'
import image190 from '../images/mainstore/image190.webp'
import image191 from '../images/mainstore/image191.webp'
import image192 from '../images/mainstore/image192.webp'
import image193 from '../images/mainstore/image193.webp'
import image194 from '../images/mainstore/image194.webp'
import image195 from '../images/mainstore/image195.webp'
import image196 from '../images/mainstore/image196.webp'
import image197 from '../images/mainstore/image197.webp'
import image198 from '../images/mainstore/image198.webp'
import image199 from '../images/mainstore/image199.webp'
import image200 from '../images/mainstore/image200.webp'
import image201 from '../images/mainstore/image201.webp'
import image202 from '../images/mainstore/image202.webp'
import image203 from '../images/mainstore/image203.webp'
import image204 from '../images/mainstore/image204.webp'
import image205 from '../images/mainstore/image205.webp'
import image206 from '../images/mainstore/image206.webp'
import image207 from '../images/mainstore/image207.webp'
import image208 from '../images/mainstore/image208.webp'
import image209 from '../images/mainstore/image209.webp'
import image210 from '../images/mainstore/image210.webp'
import image211 from '../images/mainstore/image211.webp'
import image212 from '../images/mainstore/image212.webp'
import image213 from '../images/mainstore/image213.webp'
import image214 from '../images/mainstore/image214.webp'
import image215 from '../images/mainstore/image215.webp'
import image216 from '../images/mainstore/image216.webp'
import image217 from '../images/mainstore/image217.webp'
import image218 from '../images/mainstore/image218.webp'
import image219 from '../images/mainstore/image219.webp'
import image220 from '../images/mainstore/image220.webp'
import image221 from '../images/mainstore/image221.webp'
import image222 from '../images/mainstore/image222.webp'
import image223 from '../images/mainstore/image223.webp'
import image224 from '../images/mainstore/image224.webp'
import image225 from '../images/mainstore/image225.webp'
import image226 from '../images/mainstore/image226.webp'
import image227 from '../images/mainstore/image227.webp'
import image228 from '../images/mainstore/image228.webp'
import image229 from '../images/mainstore/image229.webp'
import image230 from '../images/mainstore/image230.webp'
import image231 from '../images/mainstore/image231.webp'
import image232 from '../images/mainstore/image232.webp'
import image233 from '../images/mainstore/image233.webp'
import image234 from '../images/mainstore/image234.webp'
import image235 from '../images/mainstore/image235.webp'
import image236 from '../images/mainstore/image236.webp'
import image237 from '../images/mainstore/image237.webp'
import image238 from '../images/mainstore/image238.webp'
import image239 from '../images/mainstore/image239.webp'
import image240 from '../images/mainstore/image240.webp'
import image241 from '../images/mainstore/image241.webp'
import image242 from '../images/mainstore/image242.webp'
import image243 from '../images/mainstore/image243.webp'
import image244 from '../images/mainstore/image244.webp'
import image245 from '../images/mainstore/image245.webp'
import image246 from '../images/mainstore/image246.webp'
import image247 from '../images/mainstore/image247.webp'
import image248 from '../images/mainstore/image248.webp'
import image249 from '../images/mainstore/image249.webp'
import image250 from '../images/mainstore/image250.webp'
import image251 from '../images/mainstore/image251.webp'
import image252 from '../images/mainstore/image252.webp'
import image253 from '../images/mainstore/image253.webp'
import image254 from '../images/mainstore/image254.webp'
import image255 from '../images/mainstore/image255.webp'
import image256 from '../images/mainstore/image256.webp'
import image257 from '../images/mainstore/image257.webp'
import image258 from '../images/mainstore/image258.webp'
import image259 from '../images/mainstore/image259.webp'
import image260 from '../images/mainstore/image260.webp'
import image261 from '../images/mainstore/image261.webp'
import image262 from '../images/mainstore/image262.webp'
import image263 from '../images/mainstore/image263.webp'
import image264 from '../images/mainstore/image264.webp'
import image265 from '../images/mainstore/image265.webp'
import image266 from '../images/mainstore/image266.webp'
import image267 from '../images/mainstore/image267.webp'
import image268 from '../images/mainstore/image268.webp'
import image269 from '../images/mainstore/image269.webp'
import image270 from '../images/mainstore/image270.webp'
import image271 from '../images/mainstore/image271.webp'
import image272 from '../images/mainstore/image272.webp'
import image273 from '../images/mainstore/image273.webp'
import image274 from '../images/mainstore/image274.webp'
import image275 from '../images/mainstore/image275.webp'
import image276 from '../images/mainstore/image276.webp'
import image277 from '../images/mainstore/image277.webp'
import image278 from '../images/mainstore/image278.webp'
import image279 from '../images/mainstore/image279.webp'
import image280 from '../images/mainstore/image280.webp'
import image281 from '../images/mainstore/image281.webp'
import image282 from '../images/mainstore/image282.webp'
import image283 from '../images/mainstore/image283.webp'
import image284 from '../images/mainstore/image284.webp'
import image285 from '../images/mainstore/image285.webp'
import image286 from '../images/mainstore/image286.webp'
import image287 from '../images/mainstore/image287.webp'
import image288 from '../images/mainstore/image288.webp'
import image289 from '../images/mainstore/image289.webp'
import image290 from '../images/mainstore/image290.webp'
import image291 from '../images/mainstore/image291.webp'
import image292 from '../images/mainstore/image292.webp'
import image293 from '../images/mainstore/image293.webp'
import image294 from '../images/mainstore/image294.webp'
import image295 from '../images/mainstore/image295.webp'
import image296 from '../images/mainstore/image296.webp'
import image297 from '../images/mainstore/image297.webp'
import image298 from '../images/mainstore/image298.webp'
import image299 from '../images/mainstore/image299.webp'
import image300 from '../images/mainstore/image300.webp'
import image301 from '../images/mainstore/image301.webp'
import image302 from '../images/mainstore/image302.webp'
import image303 from '../images/mainstore/image303.webp'
import image304 from '../images/mainstore/image304.webp'
import image305 from '../images/mainstore/image305.webp'
import image306 from '../images/mainstore/image306.webp'
import image307 from '../images/mainstore/image307.webp'
import image308 from '../images/mainstore/image308.webp'
import image309 from '../images/mainstore/image309.webp'
import image310 from '../images/mainstore/image310.webp'
import image311 from '../images/mainstore/image311.webp'
import image312 from '../images/mainstore/image312.webp'
import image313 from '../images/mainstore/image313.webp'
import image314 from '../images/mainstore/image314.webp'
import image315 from '../images/mainstore/image315.webp'
import image316 from '../images/mainstore/image316.webp'
import image317 from '../images/mainstore/image317.webp'
import image318 from '../images/mainstore/image318.webp'
import image319 from '../images/mainstore/image319.webp'
import image320 from '../images/mainstore/image320.webp'
import image321 from '../images/mainstore/image321.webp'
import image322 from '../images/mainstore/image322.webp'
import image323 from '../images/mainstore/image323.webp'
import image324 from '../images/mainstore/image324.webp'
import image325 from '../images/mainstore/image325.webp'
import image326 from '../images/mainstore/image326.webp'
import image327 from '../images/mainstore/image327.webp'
import image328 from '../images/mainstore/image328.webp'
import image329 from '../images/mainstore/image329.webp'
import image330 from '../images/mainstore/image330.webp'
import image331 from '../images/mainstore/image331.webp'
import image332 from '../images/mainstore/image332.webp'
import image333 from '../images/mainstore/image333.webp'
import image334 from '../images/mainstore/image334.webp'
import image335 from '../images/mainstore/image335.webp'
import image336 from '../images/mainstore/image336.webp'
import image337 from '../images/mainstore/image337.webp'
import image338 from '../images/mainstore/image338.webp'
import image339 from '../images/mainstore/image339.webp'
import image340 from '../images/mainstore/image340.webp'
import image341 from '../images/mainstore/image341.webp'
import image342 from '../images/mainstore/image342.webp'
import image343 from '../images/mainstore/image343.webp'
import image344 from '../images/mainstore/image344.webp'
import image345 from '../images/mainstore/image345.webp'
import image346 from '../images/mainstore/image346.webp'
import image347 from '../images/mainstore/image347.webp'
import image348 from '../images/mainstore/image348.webp'
import image349 from '../images/mainstore/image349.webp'
import image350 from '../images/mainstore/image350.webp'
import image351 from '../images/mainstore/image351.webp'
import image352 from '../images/mainstore/image352.webp'
import image353 from '../images/mainstore/image353.webp'
import image354 from '../images/mainstore/image354.webp'
import image355 from '../images/mainstore/image355.webp'
import image356 from '../images/mainstore/image356.webp'
import image357 from '../images/mainstore/image357.webp'
import image358 from '../images/mainstore/image358.webp'
import image359 from '../images/mainstore/image359.webp'
import image360 from '../images/mainstore/image360.webp'
import image361 from '../images/mainstore/image361.webp'
import image362 from '../images/mainstore/image362.webp'
import image363 from '../images/mainstore/image363.webp'
import image364 from '../images/mainstore/image364.webp'
import image365 from '../images/mainstore/image365.webp'
import image366 from '../images/mainstore/image366.webp'
import image367 from '../images/mainstore/image367.webp'
import image368 from '../images/mainstore/image367.webp'
import image369 from '../images/mainstore/image369.webp'
import image370 from '../images/mainstore/image370.webp'
import image371 from '../images/mainstore/image371.webp'
import image372 from '../images/mainstore/image372.webp'
import image373 from '../images/mainstore/image373.webp'
import image374 from '../images/mainstore/image374.webp'
import image375 from '../images/mainstore/image375.webp'
import image376 from '../images/mainstore/image376.webp'
import image377 from '../images/mainstore/image377.webp'
import image378 from '../images/mainstore/image378.webp'
import image379 from '../images/mainstore/image379.webp'
import image380 from '../images/mainstore/image380.webp'
import image381 from '../images/mainstore/image381.webp'
import image382 from '../images/mainstore/image382.webp'
import image383 from '../images/mainstore/image383.webp'
import image384 from '../images/mainstore/image384.webp'
import image385 from '../images/mainstore/image385.webp'
import image386 from '../images/mainstore/image386.webp'
import image387 from '../images/mainstore/image387.webp'
import image388 from '../images/mainstore/image388.webp'
import image389 from '../images/mainstore/image389.webp'
import image390 from '../images/mainstore/image390.webp'
import image391 from '../images/mainstore/image391.webp'
import image392 from '../images/mainstore/image392.webp'
import image393 from '../images/mainstore/image393.webp'
import image394 from '../images/mainstore/image394.webp'
import image395 from '../images/mainstore/image395.webp'
import image396 from '../images/mainstore/image396.webp'
import image397 from '../images/mainstore/image397.webp'
import image398 from '../images/mainstore/image398.webp'
import image399 from '../images/mainstore/image399.webp'
import image400 from '../images/mainstore/image400.webp'
import image401 from '../images/mainstore/image401.webp'
import image402 from '../images/mainstore/image402.webp'
import image403 from '../images/mainstore/image403.webp'
import image404 from '../images/mainstore/image404.webp'
import image405 from '../images/mainstore/image405.webp'
import image406 from '../images/mainstore/image406.webp'
import image407 from '../images/mainstore/image407.webp'
import image408 from '../images/mainstore/image408.webp'
import image409 from '../images/mainstore/image409.webp'
import image410 from '../images/mainstore/image410.webp'
import image411 from '../images/mainstore/image411.webp'
import image412 from '../images/mainstore/image412.webp'
import image413 from '../images/mainstore/image413.webp'
import image414 from '../images/mainstore/image414.webp'
import image415 from '../images/mainstore/image415.webp'
import image416 from '../images/mainstore/image416.webp'
import image417 from '../images/mainstore/image417.webp'
import image418 from '../images/mainstore/image418.webp'


export const images = [
    image1, image2, image3, image4, image5, image6, image7, image8,
    image9, image10, image11, image12, image13, image14, image15, image16,
    image17, image18, image19, image20, image21, image22, image23, image24,
    image25, image26, image27, image28, image1, image2, image3, image4,
    image29, image30, image31, image32, image33, image34, image35, image36,
    image37, image38, image39, image40, image41, image42, image43, image44,
    image45, image46, image47, image48, image49, image50, image51, image52,
    image53, image54, image55, image56, image57, image58, image59, image60,
    image61, image62, image63, image64, image65, image66, image67, image68,
    image69, image70, image71, image72, image73, image74, image75, image76,
    image77, image78, image79, image80, image81, image82, image83, image84,
    image85, image86, image87, image88, image89, image90, image91, image92,
    image93, image94, image95, image96, image97, image98, image99, image100,
    image101, image102, image103, image104, image105, image106, image107, image108,
    image109, image110, image111, image112, image113, image114, image115, image116,
    image117, image118, image119, image120, image121, image122, image123, image124,
    image125, image126, image127, image128, image129, image130, image131, image132,
    image133, image134, image135, image136, image137, image138, image139, image140,
    image141, image142, image143, image144, image145, image146, image147, image148,
    image149, image150, image151, image152, image153, image154, image155, image156,
    image157, image158, image159, image160, image161, image162, image163, image164,
    image165, image166, image167, image168, image169, image170, image171, image172,
    image173, image174, image175, image176, image177, image178, image179, image180,
    image181, image182, image183, image184, image185, image186, image187, image13,
    image13, image14, image14, image15, image15, image16, image16, image188,
    image189, image190, image191, image192, image193, image194, image195, image196,
    image197, image198, image199, image200, image201, image202, image203, image204,
    image205, image206, image207, image208, image209, image210, image211, image164,
    image165, image166, image167, image168, image169, image170, image171, image29,
    image29, image30, image30, image212, image213, image214, image215, image216,
    image33, image217, image34, image218, image35, image219, image36, image220,
    image221, image222, image223, image224, image225, image226, image227, image228,
    image41, image229, image42, image229, image43, image229, image44, image230,
    image231, image232, image233, image234, image235, image236, image236, image238,
    image239, image240, image241, image242, image243, image244, image245, image246,
    image53, image247, image54, image248, image249, image250, image251, image252,
    image253, image254, image255, image256, image257, image258, image259, image260,
    image261, image262, image263, image264, image265, image266, image267, image65,
    image65, image66, image66, image67, image67, image68, image68, image268,
    image269, image270, image271, image272, image273, image274, image275, image276,
    image277, image278, image279, image280, image281, image282, image283, image284,
    image285, image286, image287, image288, image289, image290, image291, image292,
    image293, image294, image295, image296, image297, image298, image299, image300,
    image301, image302, image303, image304, image305, image306, image307, image252,
    image253, image308, image309, image310, image311, image312, image313, image314,
    image315, image316, image317, image318, image319, image320, image321, image97,
    image97, image98, image98, image99, image99, image100, image100, image322,
    image323, image324, image325, image326, image327, image328, image329, image330,
    image331, image332, image333, image334, image335, image336, image337, image338,
    image109, image339, image340, image341, image342, image343, image344, image345,
    image346, image347, image348, image349, image350, image351, image352, image353,
    image354, image355, image356, image357, image358, image359, image360, image361,
    image121, image362, image122, image363, image123, image364, image365, image366,
    image367, image368, image369, image370, image371, image372, image373, image374,
    image375, image376, image377, image378, image379, image288, image289, image284,
    image285, image290, image291, image286, image287, image136, image136, image137,
    image137, image138, image138, image139, image139, image380, image140, image381,
    image382, image383, image384, image274, image275, image385, image144, image388,
    image389, image386, image387, image390, image391, image148, image148, image392,
    image393, image394, image395, image396, image397, image398, image152, image399,
    image400, image401, image402, image403, image404, image405, image156, image406,
    image407, image408, image409, image410, image411, image412, image160, image413,
    image414, image415, image416, image417, image418,
    







];


export const texts = [
    "Lavenda Floral Hatbox", "Pink Perfection Hatbox", "Birthday Vibrant Hatbox", "Perfect White Hatbox",
    "Petite Pretty Pink Peony Hatbox", "Pretty Pink Peony Hatbox", "Pretty Pink Peony - Vase", "Pretty Pink Peony",
    "Libi Hatbox", "50 Pink Peony Hatbox", "50 White Peony - Vase", "50 Mixed Peony - Ceramic",
    "Petite Pink Tulip Hatbox", "Petite Pink & White Tulip Hatbox", "Petite Mixed Tulip Hatbox", "Petite White Tulip Hatbox",
    "100 Purple Rose", "100 Pink Roses", "100 White Roses", "100 Red Roses",
    "Violet Grand", "Coraline Grand", "Vibrant Grand", "Bianca Grand",
    "Red Roses with greens", "White Roses with greens", "Pink Roses with greens", "Purple Roses with greens",
    "Lavender Floral Hatbox", "Pink Perfection Hatbox", "Vibrant Hatbox", "Perfect White Hatbox",
    "Petite Misty Bubbles Hatbox", "Petite Adelle Hatbox", "Petite Red Roses - Hatbox", "Petite Pink Roses - Hatbox",
    "Petite White Roses - Hatbox", "Petite Yellow Roses - Hatbox", "Petite Purple Roses - Hatbox", "Petite Tiffany Blue Roses - Hatbox",
    "Red Roses - Hatbox", "White Roses - Hatbox", "Pink Roses - Hatbox", "Tiffany Blue Roses - Hatbox",
    "3 Purple Orchids", "White Orchid", "2 White Orchids", "3 White Orchids",
    "Perfect White", "Lavender Floral", "Pink Perfection", "Vibrant",
    "Anniversary Red Heart", "Anniversary Pink Heart", "Anniversary White Heart", "Anniversary Tiffany Blue Heart",
    "Anniversary 25 Pink Roses", "Anniversary 25 Red Roses", "Anniversary 50 Pink Roses", "Anniversary 50 Red Roses",
    "Anniversary Aurora", "Anniversary Rosalba", "Anniversary 100 Red Roses", "Anniversary 100 Pink Roses",
    "Anniversary 50 Pink Roses - Vase", "Anniversary 100 Pink Roses - Vase", "Anniversary 50 Red Roses - Vase", "Anniversary 100 Red Roses - Vase",
    "100 Pink O'hara - Grand", "100 White O'hara - Grand", "150 Pink O'hara - Grand", "150 White O'hara - Grand",
    "Anniversary 365 Pink Roses - Box", "Anniversary Arianna", "Anniversary Violet", "Anniversary 500 Red Roses - XXL",
    "Coraline Grand", "Adelle Grand", "Violet Grand", "Bianca Grand",
    "Birthday Pink Perfection Hatbox", "Birthday Lavender Floral Hatbox", "Birthday Vibrant Hatbox", "Birthday Perfect White Hatbox",
    "Birthday Pink Perfection - Vase", "Birthday Lavender Floral - Vase", "Birthday Vibrant - Vase", "Birthday Perfect White - Vase",
    "Birthday 50 Red Roses", "Birthday 50 Pink Roses", "Birthday 50 Yellow Roses", "Birthday 50 Tiffany Blue Roses",
    "Birthday Aurora", "Birthday Pandora", "Birthday Aurelia", "Birthday Octavia",
    "Birthday Florence Blue", "Birthday Florence Pink", "Birthday Florence White", "Birthday Grand Pink Pastel",
    "100 Pink O'hara - Grand", "150 Pink O'hara - Grand", "100 White O'hara - Grand", "150 White O'hara - Grand",
    "Vibrant - XXL", "Bianca", "Violet", "Coraline",
    "Vibrant Grand", "Bianca Grand", "Violet Grand", "Coraline Grand",
    "Vibrant - Stand", "Bianca - Stand", "Violet - Stand", "Coraline - Stand",
    "Arianna", "Adelle - XXL", "200 Pink Roses - Hatbox", "365 Pink Roses - Hatbox",
    "Arianna Grand", "Adelle Grand", "365 Pink Roses", "365 Red Roses",
    "Arianna - Stand", "Adelle - Stand", "365 Pink Roses Stand", "365 Red Roses Stand",
    "500 Red Roses - XXXL", "500 White Roses - XXXL", "500 Pink Roses - XXXL", "XXXL MOM - Box",
    "Cordelia XXXL", "Peony - Letter", "Cordelia - Stand",
    "Birthday Vibrant Hatbox", "Pink Perfection Hatbox", "Perfect White Hatbox", "Lavender Floral Hatbox",
    "Petite Vibrant Hatbox - (Complimentary balloons)", "Petite Adelle Hatbox - (Complimentary balloons)", "Petite Perfect White Hatbox - (Complimentary balloons)", "Petite Misty Bubbles Hatbox - (Complimentary balloons)",
    "Petite Red Roses - Hatbox", "Red Roses - Hatbox", "XXL Roses Hatbox", "500 Red Roses - XXL",
    "Petite Pink Roses - Hatbox", "Pink Roses - Hatbox", "365 Pink Roses - Hatbox", "500 Pink Roses - XXXL",
    "365 White Roses - Hatbox", "500 White Roses - XXXL", "50 White Roses", "White Roses - Hatbox",
    "Petite Purple Roses - Hatbox", "Purple Roses - Hatbox", "Purple Heart", "Rhodora",
    "Petite Yellow Roses - Hatbox", "Yellow Roses - Hatbox", "Yellow Heart", "Aurelia",
    "Petite Tiffany Blue Roses - Hatbox", "Tiffany Blue Roses - Hatbox", "Tiffany Blue Heart", "Tiffany Blue Heart - Large"
];



export const price = [

    325.00, 325.00, 325.00, 325.00,
    245.00, 325.00, 345.00, 325.00,
    2495.00, 2695.00, 2695.00, 2995.00,
    325.00, 325.00, 325.00, 325.00,
    995.00, 995.00, 995.00, 995.00,
    2195.00, 2195.00, 2195.00, 2195.00,
    325.00, 325.00, 325.00, 325.00,
    325.00, 325.00, 325.00, 325.00,
    265.00, 245.00, 245.00, 245.00,
    245.00, 245.00, 245.00, 245.00,
    345.00, 345.00, 345.00, 395.00,
    745.00, 325.00, 545.00, 745.00,
    325.00, 325.00, 325.00, 325.00,
    395.00, 395.00, 395.00, 445.00,
    350.00, 350.00, 495.00, 495.00,
    595.00, 595.00, 995.00, 995.00,
    745.00, 1395.00, 745.00, 1395.00,
    1995.00, 1995.00, 2995.00, 2995.00,
    3995.00, 1995.00, 1995.00, 5495.00,
    2195.00, 2195.00, 2195.00, 2195.00,
    325.00, 325.00, 325.00, 325.00,
    345.00, 345.00, 345.00, 345.00,
    495.00, 495.00, 495.00, 545.00,
    595.00, 595.00, 595.00, 645.00,
    495.00, 495.00, 495.00, 1295.00,
    1995.00, 2995.00, 1995.00, 2995.00,
    1995.00, 1995.00, 1995.00, 1995.00,
    2195.00, 2195.00, 2195.00, 2195.00,
    2495.00, 2495.00, 2495.00, 2495.00,
    1995.00, 1995.00, 2295.00, 3995.00,
    2195.00, 2195.00, 3995.00, 3995.00,
    2495.00, 2495.00, 2495.00, 2495.00,
    6995.00, 6995.00, 6995.00, 6995.00,
    19995.00, 1795.00, 9495.00,
    325.00, 325.00, 325.00, 325.00,
    245.00, 245.00, 245.00, 265.00,
    245.00, 345.00, 1295.00, 5495.00,
    245.00, 345.00, 3995.00, 6995.00,
    3995.00, 6995.00, 495.00, 345.00,
    245.00, 345.00, 395.00, 595.00,
    245.00, 345.00, 395.00, 595.00,
    245.00, 395.00, 445.00, 1045.00

];