import React, { useContext } from 'react';
import { CartContext } from '../components/CartContext';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const navigate = useNavigate();
    const { cart, removeFromCart, addToCart } = useContext(CartContext);

    const calculateTotal = () => {
        return cart.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2);
    };

    const handleDecreaseQuantity = (product) => {
        if (product.quantity > 1) {
            removeFromCart(product);
        }
    };

    const handleIncreaseQuantity = (product) => {

        if (product.quantity < 10) {
            addToCart(product);
        }
    };

    function roundToNearestMultiple(num) {
        return Math.round(num / 10) * 10;
    };
    return (
        <div>
            <Header />
            <div className='cart-container'>
                <div className='cart-main-div'>
                    <header className='cart-header'>
                        <section> <p>PRODUCTS</p>  </section>
                        <section>  <p style={{ marginRight: "200px" }}>QUANTITY</p>       <p>TOTAL</p></section>
                    </header>
                    <hr style={{ margin: "10px 0" }} />
                    {cart.length === 0 ? (
                        <p>Your cart is empty</p>
                    ) : (
                        cart.map((product, index) => (
                            <div key={index} className='cart-item'>
                                <section style={{ display: "flex", marginRight: "50px" }}>
                                    <img src={product.image} alt={product.name} />
                                    <div style={{ marginLeft: "20px", textAlign: "left", width: "280px" }}>
                                        <h3>{product.name}</h3>
                                        <p style={{ marginTop: "20px" }}>
                                            Size: {product.size === "Standard" ? `Standard with Free Update to Large (${roundToNearestMultiple(product.price / 2)} AED)` : `Large with Free Update to Extra Large (${product.price} AED)`}
                                        </p>
                                    </div>
                                </section>


                                <section>
                                    <div className='quantity-div'>
                                        <button className={product.quantity === 1 ? 'minus-button disabled' : 'minus-button'} onClick={() => handleDecreaseQuantity(product)}>-</button>
                                        <p style={{ width: "70px" }}>{product.quantity}</p>
                                        <button onClick={() => handleIncreaseQuantity(product)}>+</button>
                                    </div>

                                    <button onClick={() => removeFromCart(product)} className='button-cart'>REMOVE</button>
                                </section>
                                <section style={{ width: "150px", textAlign: "right" }}>
                                    <p>AED {roundToNearestMultiple((product.price * product.quantity) / 2)}</p>
                                </section>
                            </div>
                        ))
                    )}

                    <hr style={{ margin: "10px 0" }} />
                    {cart.length > 0 && (

                        <div className='cart-total'>
                            <div style={{ display: "flex" }}>
                                <p style={{ marginRight: "60px" }}>Grand Total Incl. Tax </p>
                                <p>AED <strike>{calculateTotal()}</strike> {roundToNearestMultiple(calculateTotal() / 2)} <b style={{color:"red"}}>-50%</b> </p>
                            </div>

                            <button className='checkout' onClick={() => { navigate(`/transaction/${roundToNearestMultiple(calculateTotal() / 2)}`) }}>CHECK OUT</button>

                        </div>

                    )}
                </div>
            </div>
        </div>
    );
};

export default Cart;
