import React from 'react';

import hand from '../images/bottomicon/hand_tied-min_small.png.png'
import cargo from '../images/bottomicon/cargo-truck-min_small.png.png'
import sameday from '../images/bottomicon/same_day_delivery-min_small.png.png'
import secure from '../images/bottomicon/secure-payment-min_small.png.png'


function Bottomcomp() {
    return (
        <div>
            <div className="gray-line"></div>
            <ul className='bottom-list'>
                <li><img src={hand} /> <p>Hand Tied & Beautifully Wrapped
                    Bouquets</p></li>
                <li><img src={cargo} /><p>Next Day Flower Delivery Throughout
                    Dubai, Abu Dhabi and UAE Available</p></li>
                <li><img src={sameday} /><p>Same / Next Day Flower Delivery
                    Available Throughout UAE</p></li>
                <li><img src={secure} /><p>Secure Payments & Superb Customer
                    Service</p></li>
            </ul>

        </div>
    );
}

export default Bottomcomp;