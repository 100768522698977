import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Main.css';
import Header from '../../components/Header';

const section = require('../../images/Section.webp')
const section2 = require('../../images/section2.webp')
import mainsale from '../../images/main.png'

const rate = require('../../images/rate.png')
const trust = require('../../images/trustpilot.png')

import image1 from '../../images/mainstore/image1.png'
import image2 from '../../images/mainstore/image2.webp'
import image3 from '../../images/mainstore/image3.png'
import image4 from '../../images/mainstore/image4.png'

import image5 from '../../images/mainstore/image5.png'
import image6 from '../../images/mainstore/image6.png'
import image7 from '../../images/mainstore/image7.png'
import image8 from '../../images/mainstore/image8.png'

import image9 from '../../images/mainstore/image9.png'
import image10 from '../../images/mainstore/image10.png'
import image11 from '../../images/mainstore/image11.png'
import image12 from '../../images/mainstore/image12.png'

import image13 from '../../images/mainstore/image13.png'
import image14 from '../../images/mainstore/image14.png'
import image15 from '../../images/mainstore/image15.png'
import image16 from '../../images/mainstore/image16.png'

import image17 from '../../images/mainstore/image17.png'
import image18 from '../../images/mainstore/image18.png'
import image19 from '../../images/mainstore/image19.png'
import image20 from '../../images/mainstore/image20.png'


import image21 from '../../images/mainstore/image21.png'
import image22 from '../../images/mainstore/image22.png'
import image23 from '../../images/mainstore/image23.png'
import image24 from '../../images/mainstore/image24.png'

import vogue from '../../images/firmlogos/Vogue-Logo.png.png'
import bazar from '../../images/firmlogos/HBA-logo.png.png'
import cosmo from '../../images/firmlogos/Comopolitan_Magazine_Logo-min.png.png'
import woman from '../../images/firmlogos/Emirates-Women-Logo.png.png'
import timeout from '../../images/firmlogos/Timeout-Dubai-Logo.png.png'
import thenational from '../../images/firmlogos/The-national-Logo.png.png'

import zahart from '../../images/firmlogos/Zahrat-Alkhaleej-Logo.png.png'
import marie from '../../images/firmlogos/Marie-claire-logo.jpg.png'
import grazie from '../../images/firmlogos/Grazia-Logo-min.png.png'
import forbes from '../../images/firmlogos/Forbes_Logo.png.png'
import elle from '../../images/firmlogos/elle-logo.png.png'
import gulf from '../../images/firmlogos/GB-black-1.png.png'

import recentimage1 from '../../images/recentimages/recent1.avif'
import recentimage2 from '../../images/recentimages/recent2.avif'
import recentimage3 from '../../images/recentimages/recent3.avif'
import recentimage4 from '../../images/recentimages/recent4.avif'



import Bottomcomp from '../../components/Bottomcomp';

const Votes = () => (
    <div>
        <ul className="card-list">
            <li className="card"><p className='cardfirstp'>Excellent</p><br /> <img src={rate} /> <br />Based on <u>9,840 reviews</u><br /> <img src={trust} style={{ marginTop: "20px" }} /></li>

            <li className="card"><div><img src={rate} className='imgvote' /> <span>Verified</span></div><b>Great service Great value</b><br /> <p style={{ fontSize: "15px", marginTop: "10px" }}>Great service Great value</p> <br /> <p style={{ fontSize: "12px" }}>Keisuke Kutomi, <span>3 hours ago</span></p></li>

            <li className="card"><div><img src={rate} className='imgvote' /> <span>Verified</span></div><b>Amazing from start to finish</b><br /><p style={{ fontSize: "15px", marginTop: "10px" }}> Amazing from start to finish! Kept me up to date once flowers were made with a v...</p><br /><p style={{ fontSize: "12px" }}>Mr D J, <span>20 hours ago</span></p> </li>

            <li className="card"><div><img src={rate} className='imgvote' /> <span>Verified</span></div><b>Happy costumer</b><br /> <p style={{ fontSize: "15px", marginTop: "10px" }}>Happy costumer</p> <br /> <p style={{ fontSize: "12px" }}>Abensour, <span>21 hours ago</span></p></li>

            <li className="card"><div><img src={rate} className='imgvote' /> <span>Verified</span></div><b>Beautiful arrangement</b><br /> <p style={{ fontSize: "15px", marginTop: "10px" }}>Beautiful arrangement</p> <br /> <p style={{ fontSize: "12px" }}>Dana, <span>2 days ago</span></p></li>
        </ul>


    </div>

);

const texts = [
    "Lavenda Floral Hatbox",
    "Pink Perfection Hatbox",
    "Birthday Vibrant Hatbox",
    "Perfect White Hatbox",

    "Petite Pretty Pink Peony Hatbox",
    "Pretty Pink Peony Hatbox",
    "Pretty Pink Peony - Vase",
    "Pretty Pink Peony",

    "Libi Hatbox",
    "50 Pink Peony Hatbox",
    "50 White Peony - Vase",
    "50 Mixed Peony - Ceramic",

    "Petite Pink Tulip Hatbox",
    "Petite Pink & White Tulip Hatbox",
    "Petite Mixed Tulip Hatbox",
    "Petite White Tulip Hatbox",

    "100 Purple Rose",
    "100 Pink Roses",
    "100 White Roses",
    "100 Red Roses",

    "Violet Grand",
    "Coraline Grand",
    "Vibrant Grand",
    "Bianca Grand"

];

const images = [
    image1,
    image2,
    image3,
    image4,

    image5,
    image6,
    image7,
    image8,

    image9,
    image10,
    image11,
    image12,

    image13,
    image14,
    image15,
    image16,

    image17,
    image18,
    image19,
    image20,

    image21,
    image22,
    image23,
    image24,


];

const price = [
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 170 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>2,495.00</strike> 1 250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,495.00</strike> 1 250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,695.00</strike> 1 350 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,695.00</strike> 1 350 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,995.00</strike> 1 450 <b style={{ color: "red" }}>-50%</b></>,


    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>995.00</strike> 550 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>995.00</strike> 550 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>995.00</strike> 550 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>995.00</strike> 550 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
];


const FirmShow = () => (
    <div className='firmshowdiv'>
        <ul className='firmshow'>
            <li><img src={vogue} /></li>
            <li><img src={bazar} /></li>
            <li><img src={cosmo} /></li>
            <li><img src={woman} /></li>
            <li><img src={timeout} /></li>
            <li><img src={thenational} /></li>
        </ul>
    </div>


);
const FirmShow2 = () => (
    <div className='firmshowdiv'>
        <ul className='firmshow'>
            <li><img src={zahart} /></li>
            <li><img src={marie} /></li>
            <li><img src={grazie} /></li>
            <li><img src={forbes} /></li>
            <li><img src={elle} /></li>
            <li><img src={gulf} /></li>
        </ul>


    </div>


);

const Recent = () => (
    <div className='recent'>
        <div className='recrenttext'>
            <p className='text-with-line'>Recent Press Releases</p>
        </div>
        <ul className='recent-list'>
            <li className='recent-card'><img src={recentimage1} /> <p style={{ marginTop: "10px" }}>Our Favourite Dubai Florist On How To
                Create A Picture-Perfect Tablescape</p>
                <p style={{ marginTop: "30px" }}>With an impressive 183k Instagram followers and
                    143k on TikTok, Flowers.ae is rapidly becoming the
                    UAE`s go-to luxury florist. How? By thinking outside
                    the pot: The Dubai florist uses the highest-quality</p>

                <p style={{ marginTop: "20px" }}>By Harper's Bazaar Staff</p>
            </li>

            <li className='recent-card'><img src={recentimage2} /> <p style={{ marginTop: "10px" }}>This luxe floral boutique hosted an
                immersive Peonies workshop in Dubai</p>
                <p style={{ marginTop: "30px" }}>The gift of learning a new skill lasts a lifetime. To
                    unleash their inner artist, the UAE-based floral
                    boutique, Flowers.ae hosted an exclusive workshop
                    to delve into the world of floristry.</p>
                <p style={{ marginTop: "20px" }}>By Sarah Joseph</p>
            </li>

            <li className='recent-card'><img src={recentimage3} /><p style={{ marginTop: "10px" }}>8 places that do same-day flower delivery in
                the UAE</p>
                <p style={{ marginTop: "30px" }}>
                    Whether you’re looking for birthday or anniversary
                    gifts, we’ve listed seven perfect florists in the UAE
                    that do same-day flower delivery so that you don’t
                    have to. Whether you’re shopping for your s/o, your
                </p>
                <p style={{ marginTop: "20px" }}>By Cosmopolitan</p>
            </li>

            <li className='recent-card'><img src={recentimage4} /> <p style={{ marginTop: "10px" }}>There’s a new favourite florist in town:
                Flowers.ae</p>
                <p style={{ marginTop: "30px" }}>Whether you want to give your loved one a
                    thoughtful surprise or blow their mind for a big
                    occasion, this Dubai-based online florist has flower
                    arrangement delivery for all your needs.</p>
                <p style={{ marginTop: "20px" }}>By Amulya Kundran</p>
            </li>

        </ul>

    </div>
);


function Main() {
    const navigate = useNavigate();

    const handleViewClick = (storeIndex) => {
        navigate(`/store/${storeIndex}`);
    };
    const Store = () => (
        <div>
            <ul className="store-list">
                {[...Array(24)].map((_, index) => (
                    <li key={index} className="store">
                        <img src={images[index % images.length]} alt={`Image ${index + 1}`} onClick={() => handleViewClick(index)} />
                        <p>{texts[index]}</p>
                        <p>{price[index]}</p>
                        <button onClick={() => handleViewClick(index)}>View</button>
                    </li>
                ))}
            </ul>



        </div>

    );

    return (
        <div>
            <Header />
            <div className="image-container" onClick={()=>{ navigate('/ourcompleterange')}}>
                {window.innerWidth <= 1000 ? (
                    <div>
                        <img src={mainsale} alt="Responsive Image" className="mainimg-sale" />
                        <img src={section2} alt="Responsive Image" className="mainimg" />
                    </div>
                ) : (
                    <div>
                        <img src={mainsale} alt="Responsive Image" className="mainimg-sale" />
                        <img src={section} alt="Responsive Image" className="mainimg" />
                    </div>
                )} 
            </div>
            <Votes />
            <Store />
            <FirmShow />
            <FirmShow2 />
            <Recent />
            <Bottomcomp />
        </div>
    );
}

export default Main;
