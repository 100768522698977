import React from 'react';
import Header from '../components/Header';
import Bottomcomp from '../components/Bottomcomp';
import { useNavigate } from 'react-router-dom';

import image132 from '../images/mainstore/image132.png'
import image133 from '../images/mainstore/image133.png'
import image134 from '../images/mainstore/image134.png'
import image135 from '../images/mainstore/image135.png'

import image136 from '../images/mainstore/image136.png'
import image137 from '../images/mainstore/image137.png'
import image138 from '../images/mainstore/image138.png'
import image139 from '../images/mainstore/image139.png'

const texts = [
    "Birthday Vibrant Hatbox", "Pink Perfection Hatbox", "Perfect White Hatbox", "Lavender Floral Hatbox",
    "Petite Vibrant Hatbox - (Complimentary balloons)", "Petite Adelle Hatbox - (Complimentary balloons)", "Petite Perfect White Hatbox - (Complimentary balloons)", "Petite Misty Bubbles Hatbox - (Complimentary balloons)"

];

const images = [
    image132, image133, image134, image135,
    image136, image137, image138, image139,
];

const price = [
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>265.00</strike> 130 <b style={{ color: "red" }}>-50%</b></>,

];




function SpecialOffers() {

    const navigate = useNavigate();

    const handleViewClick = (storeIndex) => {
        navigate(`/store/${storeIndex}`);
    };


    const Store = () => (
        <div>
            <ul className="store-list">
                {[...Array(8)].map((_, index) => (
                    <li key={index} className="store">
                        <img src={images[index % images.length]} alt={`Image ${index + 1}`} onClick={() => handleViewClick(index + 135)} />
                        <p>{texts[index]}</p>
                        <p>{price[index]}</p>
                        <button onClick={() => handleViewClick(index + 135)}>View</button>
                    </li>
                ))}
            </ul>



        </div>

    );


    return (
        <div>
            <Header />
            <p style={{ marginLeft: "50px" }} className='pfromsites'><a href='/'>Home</a> <span className="dot-in-middle"></span> Special Offers</p>
            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Special Offers</h1>
                    <p>Don't miss out on our special offers for beautiful flowers that are sure to brighten up any occasion.</p>
                </div>
            </div>
            <Store />

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Special Offers From Flowers.ae - Online Flower Delivery</h1>
                    <p>Gift giving is a big part of many cultures, and it is a great way to show someone that you care. Upgrade your traditional way of giving gifts, don't just give a bouquet of flowers, spice it up by adding balloons to make your gift
                        more special and look like it's well-thought. You can also add a delicious and mouth watering cake, this will surely make your loved ones a lot more happier.</p>
                    <p>Special offers are one of the best ways to save money when making purchases. Whether it’s a discounted item, a free upgrade, or a promotional code, taking advantage of these offers can provide great savings and value. One
                        such offer is a free upgrade to a large bouquet of flower arrangement with cake and balloons. This type of offer can be especially beneficial for those purchasing gifts for special occasions, such as an anniversary or a birthday.</p>
                    <p>Flowers.ae - online flower delivery,  the leading flower shop in Dubai! Here you can find the best deals on our selection of unique flower arrangements, bouquets, and gifts. We have something special for everyone, so take a look
                        at our amazing offers and get the most out of your flower shopping experience.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Unforgettable Special Offers: Hatbox Delights and More at Flowers.ae</h1>
                    <p>At Flowers.ae, we believe in making every moment special. That's why we are excited to present our exclusive Special Offers page, where you'll find delightful promotions and deals designed to enhance your gifting experience.
                        From Hatbox Special Offers that include complimentary occasion balloons to enticing Hatbox Giftsets with teddy bears and balloons, we have something extraordinary for every occasion. Discover the magic of our Special Offers
                        and let us help you create unforgettable moments.</p>
                </div>
            </div>


            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Hatbox Special Offer: Complimentary Occasion Balloons</h1>
                    <p>Our Hatbox Special Offer is a true celebration of joy and color. When you choose a selected Hatbox arrangement, we're delighted to offer complimentary occasion balloons to add an extra touch of festivity to your gift. Whether
                        you're celebrating a birthday, anniversary, or any special milestone, these balloons will bring smiles and excitement to the occasion.</p>
                </div>
            </div>


            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Hatbox Gift Set: Teddy Bear and Balloons</h1>
                    <p>Take your gifting experience to the next level with our Hatbox Gift Set. This enchanting package includes a beautifully arranged Hatbox filled with stunning blooms, a cuddly teddy bear, and a collection of vibrant balloons. It's
                        the perfect way to show someone you care and make them feel truly cherished. Surprise your loved ones with this delightful Hatbox Gift Set and create magical memories together.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Standard Hatbox: Free Upgrade to Larges</h1>
                    <p>We believe in exceeding expectations, which is why we're thrilled to offer a free upgrade to a larger Hatbox. When you select a standard Hatbox arrangement, we'll automatically upgrade it to a larger size, ensuring an even more
                        impressive display of flowers. With this Special Offer, you can make a grander statement and make your gift truly unforgettable.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Large Hatbox: Free Upgrade to Extra Large</h1>
                    <p>For those seeking an extraordinary gifting experience, our Large Hatbox Special Offer comes with a fantastic upgrade. When you choose a large Hatbox arrangement, we'll upgrade it to an extra-large size at no additional cost.
                        This means more blooms, more beauty, and more impact. Let your gesture speak volumes with our larger-than-life Hatbox arrangement.</p>
                    <p>Flowers.ae is dedicated to providing you with exceptional offers and deals, allowing you to express your love and appreciation without compromising on quality. Our Special Offers page is updated regularly, so you can always
                        find something exciting and unique to suit your gifting needs. Take advantage of these exclusive promotions and enjoy the art of giving with Flowers.ae.</p>
                    <p>Please note that our special offers may vary and are subject to availability. Be sure to check our Special Offers page for the latest updates and take advantage of these incredible deals. Contact us and we will make every
                        occasion extraordinary with our exceptional Hatbox arrangements and more. Check out our Flowers Collection and Extras bundles and we will help you create the perfect gift set combinations for your special occasion.</p>
                </div>
            </div>

            <Bottomcomp />
        </div>
    );
}

export default SpecialOffers;
