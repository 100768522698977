import React from 'react';

import Header from '../../components/Header';
import Bottomcomp from '../../components/Bottomcomp';
import GreyContainer from '../../components/GreyContainer';

import notaccept from '../../images/notaccept.png';
const ErrorTrans = () => {

    return (
        <div>
            <Header />
            <div className='div-eror'>
                <div>
                    <p><img src={notaccept} />Unfortunately, your payment cannot be processed due to incorrect data.</p>
                    <p>Please double-check the payment information and make the necessary corrections.</p>
                    <p>Thank you for your understanding.</p>
                </div>
            </div>
            <GreyContainer />
            <Bottomcomp />
        </div>
    );
};

export default ErrorTrans;
