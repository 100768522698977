import React from 'react';
import Header from '../components/Header';
import Bottomcomp from '../components/Bottomcomp';
import { useNavigate } from 'react-router-dom';

import image140 from '../images/mainstore/image140.png'
import image141 from '../images/mainstore/image141.png'
import image142 from '../images/mainstore/image142.png'
import image143 from '../images/mainstore/image143.png'

import image144 from '../images/mainstore/image144.png'
import image145 from '../images/mainstore/image145.png'
import image146 from '../images/mainstore/image146.png'
import image147 from '../images/mainstore/image147.png'

import image148 from '../images/mainstore/image148.png'
import image149 from '../images/mainstore/image149.png'
import image150 from '../images/mainstore/image150.png'
import image151 from '../images/mainstore/image151.png'

import image152 from '../images/mainstore/image152.png'
import image153 from '../images/mainstore/image153.png'
import image154 from '../images/mainstore/image154.png'
import image155 from '../images/mainstore/image155.png'

import image156 from '../images/mainstore/image156.png'
import image157 from '../images/mainstore/image157.png'
import image158 from '../images/mainstore/image158.png'
import image159 from '../images/mainstore/image159.png'

import image160 from '../images/mainstore/image160.png'
import image161 from '../images/mainstore/image161.png'
import image162 from '../images/mainstore/image162.png'
import image163 from '../images/mainstore/image163.png'

const texts = [
    "Petite Red Roses - Hatbox", "Red Roses - Hatbox", "XXL Roses Hatbox", "500 Red Roses - XXL",
    "Petite Pink Roses - Hatbox", "Pink Roses - Hatbox", "365 Pink Roses - Hatbox", "500 Pink Roses - XXXL",
    "365 White Roses - Hatbox", "500 White Roses - XXXL", "50 White Roses", "White Roses - Hatbox",
    "Petite Purple Roses - Hatbox", "Purple Roses - Hatbox", "Purple Heart", "Rhodora",
    "Petite Yellow Roses - Hatbox", "Yellow Roses - Hatbox", "Yellow Heart", "Aurelia",
    "Petite Tiffany Blue Roses - Hatbox", "Tiffany Blue Roses - Hatbox", "Tiffany Blue Heart", "Tiffany Blue Heart - Large"
];

const images = [
    image140, image141, image142, image143,
    image144, image145, image146, image147,
    image148, image149, image150, image151,
    image152, image153, image154, image155,
    image156, image157, image158, image159,
    image160, image161, image162, image163,
];

const price = [
    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 170 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,295.00</strike> 600 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>5,495.00</strike> 2,250 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 170 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>3,995.00</strike> 2,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>6,995.00</strike> 3,500 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>3,995.00</strike> 2,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>6,995.00</strike> 3,500 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>495.00</strike> 250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 170 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 170 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>395.00</strike> 200 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>595.00</strike> 300 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 170 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>395.00</strike> 200 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>595.00</strike> 300 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>395.00</strike> 200 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>445.00</strike> 225 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,045.00</strike> 525 <b style={{ color: "red" }}>-50%</b></>,

];




function RoseCollection() {

    const navigate = useNavigate();

    const handleViewClick = (storeIndex) => {
        navigate(`/store/${storeIndex}`);
    };


    const Store = () => (
        <div>
            <ul className="store-list">
                {[...Array(24)].map((_, index) => (
                    <li key={index} className="store">
                        <img src={images[index % images.length]} alt={`Image ${index + 1}`} onClick={() => handleViewClick(index + 143)} />
                        <p>{texts[index]}</p>
                        <p>{price[index]}</p>
                        <button onClick={() => handleViewClick(index + 143)}>View</button>
                    </li>
                ))}
            </ul>



        </div>

    );


    return (
        <div>
            <Header />
            <p style={{ marginLeft: "50px" }} className='pfromsites'><a href='/'>Home</a> <span className="dot-in-middle"></span> Rose Collection</p>
            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Rose Collection</h1>
                    <p>Roses are typically associated with love and romance, but each different colored rose actually symbolizes something distinct.</p>
                </div>
            </div>
            <Store />

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Why Send Birthday Flowers with Flowers.ae?</h1>
                    <p>Flowers are a timeless way to express your affection and celebrate life's special moments. At Flowers.ae, we specialize in crafting and delivering the perfect birthday flower gifts that speak volumes of your love and thoughtfulness.</p>
                </div>
            </div>


            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Can I Send Birthday Flowers on the Same Day?</h1>
                    <p>Absolutely! We pride ourselves on our expedited same-day birthday flowers delivery service. For those last-minute birthday surprises, simply place your order by 10 P.M., and we'll ensure your beautiful birthday flowers reach their
                        destination the same day.</p>
                </div>
            </div>


            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>What Types of Birthday Flowers Can I Order?</h1>
                    <p>From luxurious bouquets to unique arrangements, our birthday collection caters to all tastes and preferences. Whether you're looking for birthday flowers for her, him, or anyone special, our range includes everything from elegant
                        roses to exotic blooms, paired perfectly with balloons or personalized gifts.</p>
                </div>
            </div>



            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>How Do I Choose the Best Birthday Flowers?</h1>
                    <p>Selecting the right birthday flowers depends on the recipient's personality and your relationship with them. Consider their favorite colors, flowers, or even the message you want to convey. For a more detailed guide, our birthday
                        flower meaning and ideas section offers insights into making your choice truly special.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>What Makes Flowers.ae the Best Choice for Birthday Flower Delivery?</h1>
                    <p>Our commitment to quality, punctuality, and exceptional customer service sets us apart. With Flowers.ae, ordering birthday flowers online in Dubai and across the UAE is seamless and reliable. Enjoy our wide selection of birthday
                        flowers online delivery options, including birthday flowers same day delivery, to make sure your warm wishes and love are conveyed on time and in the most beautiful manner.</p>
                </div>
            </div>


            <Bottomcomp />
        </div>
    );
}

export default RoseCollection;
