import React, { useState } from 'react';

import facebook from '../images/sociallogo/facebook.svg'
import inst from '../images/sociallogo/instagramm.svg'
import tiktok from '../images/sociallogo/tiktok.svg'

import cart from '../images/cart.svg'
import arrow from '../images/Vector.svg'
import logopng from '../images/black-logo.svg'



const BlackUp = () => (
    <div className='headerdiv'>
        <ul className='headerlist'>
            <li style={{ marginRight: "10%" }}>Outstanding Service</li>
            <li>Free Delivery to all Emirates</li>
            <li style={{ marginLeft: "10%" }}>Video Appraval on all orders</li>
        </ul>

    </div>
);


const WhiteUp = () => (
    <div className='whitetop'>
        <div className="left">
            <a href='https://www.facebook.com'><img src={facebook} /></a>
            <a href='https://www.facebook.com'><img src={inst} /></a>
            <a href='https://www.tiktok.com'><img src={tiktok} style={{ borderRight: "none" }} /></a>
            <p>+971 4 718 1991</p></div>
        <div className="center"><a href='/'><img src={logopng} /></a></div>
        <div className="right"><a href='/cart'><img src={cart} /></a> </div>

    </div>


);



const MenuFlower = () => (
    <div className='menuflower'>
        <ul className='menulist'>
            <li><a href='/ourcompleterange'>Our Complete Range</a></li>
            <li><a href='/birthdayflowers'>Birthday Flowers</a></li>
            <li><a href='/anniversaryflowers'>Anniversary Flowers</a></li>
            <li><a href='/vipflowers'>VIP Flowers</a></li>
            <li><a href='/specialoffers'>Special Offers</a></li>
            <li><a href='/rosecollection'>Rose Collection</a></li>
        </ul>

    </div>
);
function Header() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    const Whiteupmobile = () => (

        <div className='whitetopmobile'>
            <div className={`leftmobile ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="centermobile"><a href='/'><img src={logopng} alt="Logo" /></a></div>
            <div className="rightmobile"><a href='/cart'><img src={cart} alt="Cart" /></a></div>

            {isOpen && (
                <div id="menu" className="menu">
                    <div style={{display:"flex", justifyContent:"flex-end"}}>
                    <button onClick={toggleMenu} className='menu-btn'><img src={arrow} style={{height:"50px"}}/></button>
                    </div>
                    <ul>
                        <li><a href='/ourcompleterange'>Our Complete Range</a></li>
                        <li><a href='/birthdayflowers'>Birthday Flowers</a></li>
                        <li><a href='/anniversaryflowers'>Anniversary Flowers</a></li>
                        <li><a href='/vipflowers'>VIP Flowers</a></li>
                        <li><a href='/specialoffers'>Special Offers</a></li>
                        <li><a href='/rosecollection'>Rose Collection</a></li>
                    </ul>
                </div>
            )}
        </div>
    );
    return (
        <div>
            <BlackUp />
            <WhiteUp />
            <MenuFlower />
            <Whiteupmobile />


        </div>
    );
}

export default Header;