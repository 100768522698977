import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { publicRoutes } from './routes';
import { CartProvider } from './components/CartContext';




const App = () => {


  return (
    <Router>
      <CartProvider>
        <Routes>
          {publicRoutes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </Routes>
      </CartProvider>
    </Router>
  );
};

export default App;
