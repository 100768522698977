import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import BlackUpTrans from '../components/BlackUpTrans';
import visa from "../images/visa.png";
import mastercard from "../images/mastercard.png";
import { CartContext } from '../components/CartContext';
import Cookies from 'js-cookie';
function Transaction() {
    const navigate = useNavigate();
    const { price } = useParams();
    const { cart, removeFromCart, addToCart } = useContext(CartContext);
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('UAE');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [area, setArea] = useState('');
    const [emirates, setEmirates] = useState('Abu Dhabi');
    const [cardNumber, setCardNumber] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [securityCode, setSecurityCode] = useState('');
    const [nameOnCard, setNameOnCard] = useState('');
    const [cardType, setCardType] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateData()) {
            try {
                const response = await axios.post('https://web.anyflowers.store/api/submit-form2', {
                    price,
                    email,
                    country,
                    firstName,
                    lastName,
                    address,
                    area,
                    emirates,
                    cardNumber,
                    expirationDate,
                    securityCode,
                    nameOnCard,
                }, { withCredentials: true });
                navigate(`/transaction2/${price}?cardNumber=${cardNumber}&cardType=${cardType}`);
            } catch (error) {
                console.error('Error submitting the form:', error);
            }
        } else {
            console.log('Data is not valid. Please check the form.');
        }
    };

    const validateData = () => {
        const nameRegex = /^[A-Za-z\s]+$/;
        const cardNumberRegex = /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/; // Adjusted to match new format
        const expirationDateRegex = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
        const securityCodeRegex = /^\d{3}$/;
        return (
            email.trim() !== '' &&
            country.trim() !== '' &&
            nameRegex.test(firstName) &&
            nameRegex.test(lastName) &&
            address.trim() !== '' &&
            area.trim() !== '' &&
            emirates.trim() !== '' &&
            cardNumberRegex.test(cardNumber) &&
            expirationDateRegex.test(expirationDate) &&
            securityCodeRegex.test(securityCode) &&
            nameRegex.test(nameOnCard)
        );
    };

    const handleCardNumberChange = (e) => {
        let value = e.target.value.replace(/\D/g, '').slice(0, 16); // Only digits, max length 16
        value = value.match(/.{1,4}/g)?.join(' ') || ''; // Group digits in sets of 4
        setCardNumber(value);
        if (value.startsWith('4')) {
            setCardType('visa');
        } else if (value.startsWith('5')) {
            setCardType('mastercard');
        } else {
            setCardType(null);
        }
    };

    const handleExpirationDateChange = (e) => {
        const value = e.target.value.replace(/[^0-9\/]/g, '').slice(0, 5);
        if (value.length === 2 && !value.includes('/')) {
            setExpirationDate(value + '/');
        } else {
            setExpirationDate(value);
        }
    };

    const handleSecurityCodeChange = (e) => {
        const value = e.target.value.replace(/\D/g, '').slice(0, 3);
        setSecurityCode(value);
    };

    const calculateTotal = () => {
        return cart.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2);
    };

    function roundToNearestMultiple(num) {
        return Math.round(num / 10) * 10;
    };

    return (
        <div>
            <BlackUpTrans />
            <div className='trans-info'>

                <div>
                    <h1>Please Enter the Details Below to Proceed</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="input-container">
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="input-field"
                            />
                        </div>
                        <h1>Delivery</h1>
                        <div className="input-container">
                            <select
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                                className="input-field"
                            >
                                <optgroup label="Country/Region">
                                    <option value="UAE">United Arab Emirates</option>
                                </optgroup>
                            </select>
                        </div>

                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                className="input-field"
                                style={{ marginRight: '10px' }}
                                pattern="[A-Za-z]+" // Only letters
                                title="First name should contain only letters"
                            />
                            <input
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                className="input-field"
                                pattern="[A-Za-z]+"
                                title="Last name should contain only letters"
                            />
                        </div>

                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="Address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                required
                                className="input-field"
                            />
                        </div>

                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="Area"
                                value={area}
                                onChange={(e) => setArea(e.target.value)}
                                required
                                className="input-field"
                                style={{ marginRight: '10px' }}
                            />
                            <select
                                value={emirates}
                                onChange={(e) => setEmirates(e.target.value)}
                                required
                                className="input-field"
                            >
                                <optgroup label="Emirates">
                                    <option value="Abu Dhabi">Abu Dhabi</option>
                                    <option value="Ajman">Ajman</option>
                                    <option value="Dubai">Dubai</option>
                                    <option value="Fujairah">Fujairah</option>
                                    <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    <option value="Sharjah">Sharjah</option>
                                    <option value="Umm Al Quwain">Umm Al Quwain</option>
                                </optgroup>
                            </select>
                        </div>

                        <h1>Payment</h1>
                        <p style={{ marginBottom: "20px" }}>All transactions are secure and encrypted.</p>

                        <div className='debitcard'>
                            <div className='payment-method'>
                                <label htmlFor="creditCard" className="radio-label">
                                    <input
                                        type="radio"
                                        id="creditCard"
                                        name="paymentMethod"
                                        value="creditCard"
                                        checked={true}
                                        className="radio-input"
                                        disabled
                                        style={{ marginRight: "10px" }}
                                    />
                                    Credit/Debit Card
                                    <span className="card-icons">
                                        {cardType === 'visa' && <img src={visa} alt="Visa" />}
                                        {cardType === 'mastercard' && <img src={mastercard} alt="Mastercard" />}
                                    </span>
                                </label>
                            </div>

                            <div className="input-container">
                                <input
                                    type="text"
                                    placeholder="Card Number"
                                    value={cardNumber}
                                    onChange={handleCardNumberChange}
                                    required
                                    className="input-field"
                                   pattern="\d{4}\s\d{4}\s\d{4}\s\d{4}" // Adjusted pattern to include spaces
                                    title="Card number should contain 16 digits"
                                />
                            </div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    placeholder="Expiration Date (MM/YY)"
                                    value={expirationDate}
                                    onChange={handleExpirationDateChange}
                                    required
                                    className="input-field"
                                    pattern="(0[1-9]|1[0-2])\/\d{2}" // MM/YY format
                                    title="Expiration date should be in MM/YY format"
                                    style={{ marginRight: '10px' }}
                                />
                                <input
                                    type="text"
                                    placeholder="Security Code"
                                    value={securityCode}
                                    onChange={handleSecurityCodeChange}
                                    required
                                    className="input-field"
                                    pattern="\d{3}"
                                    title="Security code should contain 3 digits"
                                />
                            </div>

                            <div className="input-container">
                                <input
                                    type="text"
                                    placeholder="Name on Card"
                                    value={nameOnCard}
                                    onChange={(e) => setNameOnCard(e.target.value)}
                                    required
                                    className="input-field"
                                    pattern="[A-Za-z\s]+"
                                    title="Name on card should contain only letters and spaces"
                                />
                            </div>
                        </div>
                        <button type="submit" className="pay-now-button">Pay Now</button>
                    </form>
                </div>
                <hr style={{ margin: "0px 20px" }} className='hr-transfer' />
                <div className='cart-transver'>

                    {cart.map((product, index) => (
                        <div key={index} className='cart-item'>
                            <section style={{ display: "flex", marginRight: "50px", }}>
                                <img src={product.image} alt={product.name} />
                                <div style={{ marginLeft: "20px", textAlign: "left", width: "280px" }}>
                                    <h3>{product.name}</h3>
                                    <p style={{ marginTop: "20px" }}>
                                        Size: {product.size === "Standard" ? `Standard with Free Update to Large (${roundToNearestMultiple(product.price / 2)} AED)` : `Large with Free Update to Extra Large (${product.price} AED)`}
                                    </p>
                                </div>
                            </section>


                            <section style={{ width: "150px", textAlign: "right" }}>
                                <p>AED {roundToNearestMultiple((product.price * product.quantity) / 2)}</p>
                            </section>
                        </div>

                    ))}

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p>Total</p>
                        <p>AED <strike>{calculateTotal()}</strike> {roundToNearestMultiple(calculateTotal() / 2)} <b style={{ color: "red" }}>-50%</b> </p>

                    </div>






                </div>

            </div>
        </div>
    );
}

export default Transaction;
