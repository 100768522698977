import React from 'react';
import Header from '../components/Header';
import Bottomcomp from '../components/Bottomcomp';
import { useNavigate } from 'react-router-dom';


import image101 from '../images/mainstore/image101.png'
import image102 from '../images/mainstore/image102.png'
import image103 from '../images/mainstore/image103.png'
import image104 from '../images/mainstore/image104.png'

import image105 from '../images/mainstore/image105.png'
import image106 from '../images/mainstore/image106.png'
import image107 from '../images/mainstore/image107.png'
import image108 from '../images/mainstore/image108.png'

import image109 from '../images/mainstore/image109.png'
import image110 from '../images/mainstore/image110.png'
import image111 from '../images/mainstore/image111.png'
import image112 from '../images/mainstore/image112.png'

import image113 from '../images/mainstore/image113.png'
import image114 from '../images/mainstore/image114.png'
import image115 from '../images/mainstore/image115.png'
import image116 from '../images/mainstore/image116.png'

import image117 from '../images/mainstore/image117.png'
import image118 from '../images/mainstore/image118.png'
import image119 from '../images/mainstore/image119.png'
import image120 from '../images/mainstore/image120.png'

import image121 from '../images/mainstore/image121.png'
import image122 from '../images/mainstore/image122.png'
import image123 from '../images/mainstore/image123.png'
import image124 from '../images/mainstore/image124.png'

import image125 from '../images/mainstore/image125.png'
import image126 from '../images/mainstore/image126.png'
import image127 from '../images/mainstore/image127.png'
import image128 from '../images/mainstore/image128.png'

import image129 from '../images/mainstore/image129.png'
import image130 from '../images/mainstore/image130.png'
import image131 from '../images/mainstore/image131.png'

const texts = [
    "Vibrant - XXL", "Bianca", "Violet", "Coraline",
    "Vibrant Grand", "Bianca Grand", "Violet Grand", "Coraline Grand",
    "Vibrant - Stand", "Bianca - Stand", "Violet - Stand", "Coraline - Stand",
    "Arianna", "Adelle - XXL", "200 Pink Roses - Hatbox", "365 Pink Roses - Hatbox",
    "Arianna Grand", "Adelle Grand", "365 Pink Roses", "365 Red Roses",
    "Arianna - Stand", "Adelle - Stand", "365 Pink Roses Stand", "365 Red Roses Stand",
    "500 Red Roses - XXXL", "500 White Roses - XXXL", "500 Pink Roses - XXXL", "XXXL MOM - Box",
    "Cordelia XXXL", "Peony - Letter", "Cordelia - Stand"

];

const images = [
    image101, image102, image103, image104,
    image105, image106, image107, image108,
    image109, image110, image111, image112,
    image113, image114, image115, image116,
    image117, image118, image119, image120,
    image121, image122, image123, image124,
    image125, image126, image127, image128,
    image129, image130, image131,
];

const price = [
    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>2,495.00</strike> 1,250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,495.00</strike> 1,250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,495.00</strike> 1,250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,495.00</strike> 1,250 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,295.00</strike> 1,150 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>3,995.00</strike> 2,000 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>3,995.00</strike> 2,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>3,995.00</strike> 2,000 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>2,495.00</strike> 1,250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,495.00</strike> 1,250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,495.00</strike> 1,250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,495.00</strike> 1,250 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>6,995.00</strike> 3,500 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>6,995.00</strike> 3,500 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>6,995.00</strike> 3,500 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>6,995.00</strike> 3,500 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>19,995.00</strike> 10,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,795.00</strike> 900 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>9,495.00</strike> 4750 <b style={{ color: "red" }}>-50%</b></>,


];




function VipFlowers() {

    const navigate = useNavigate();

    const handleViewClick = (storeIndex) => {
        navigate(`/store/${storeIndex}`);
    };


    const Store = () => (
        <div>
            <ul className="store-list">
                {[...Array(31)].map((_, index) => (
                    <li key={index} className="store">
                        <img src={images[index % images.length]} alt={`Image ${index + 1}`} onClick={() => handleViewClick(index + 104)} />
                        <p>{texts[index]}</p>
                        <p>{price[index]}</p>
                        <button onClick={() => handleViewClick(index + 104)}>View</button>
                    </li>
                ))}
            </ul>



        </div>

    );


    return (
        <div>
            <Header />
            <p style={{ marginLeft: "50px" }} className='pfromsites'><a href='/'>Home</a> <span className="dot-in-middle"></span> VIP Flowers</p>
            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>VIP Flowers</h1>
                    <p>Showcasing only the highest quality of flowers, our luxury collection features arrangements of 200, 365, and 500+ stems, offering the pinnacle of high-end floral elegance.</p>
                </div>
            </div>
            <Store />
            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Luxury Flowers from Flowers.ae Flower Delivery</h1>
                    <p>Dubai is a city famed for its luxurious lifestyle, which also influences its flower industry. Dubai currently features some of the most lavish flower bouquets in the entire world because of the city's significant increase in the demand
                        for high-end flowers. Luxury flowers offer a sense of sophistication and elegance to any occasion, from large gatherings to small, private ones.</p>
                    <p>The degree of skill and passion that goes into each arrangement is one of the things that distinguishflower shops in Dubai from others.Flowers.ae flower shops are staffed by professional florists who are committed to making the
                        ideal arrangement for you and the best flower delivery service, whether you're searching for a large display for a special event or a modest bouquet for a loved one.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Level Up Your Way of Saying “I love you” and Send Flowers</h1>
                    <p>Upgrading your way of sending flowers is done best with our luxury arrangements in Dubai. Luxury flowers are great gifts to give to friends and family members. The beauty of luxury flowers is that they’re often presented as a
                        surprise, so you can’t go wrong with this thoughtful gift. They are not only beautiful and bright but also mean more than “just a flower”. Flowers can be used to convey your feelings in many ways: from expressing your deep
                        affection for someone to giving them a flower on their birthday or anniversary. For a touch of luxury, our flowers are a perfect choice. </p>
                    <p>Luxury flower arrangements are not only beautiful but also very versatile. For every preference and price range, there is a luxuryflower alternative, ranging from expansive, sweeping displays to delicate, minimalist
                        arrangements.Flowers.ae offers a distinctive and fashionable method to express your emotions, whether you're looking for a statement item for a special occasion or a considerate gift for a loved one. Send flowers to your loved
                        ones in Dubai through Flowers.ae delivery service.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Wow your Loved Ones with Luxury Flowers from Flowers.ae</h1>
                    <p>If you're looking for a way to make your next occasion a bit more exciting, then look no further than our luxury collection of flowers. A bunch of roses, tulips, hydrangeas, red and orange Gerberas, and all-yellow sunflowers make
                        the perfect flower surprise for your loved ones. Flowers.ae can help you arrange and deliver flowers through this simple gesture you will be able to spread the joy of giving without leaving anyone behind! Our luxury collection of
                        flowers adds lux to any occasion. Order flowers online today at Flowers.ae, thebest flower shop in Dubai.</p>

                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Bring Joy to your Loved ones with Luxurious Flower Delivery</h1>
                    <p>Brightly colored roses, sunflowers, and hydrangeas plus vibrant colors like yellow and pink in our luxurious collection of flowers will make you want to celebrate life and happiness with your loved ones. Order flowers online from
                        Flowers.ae so you really can do something special again, simple actions done with love by you are the best kind of gifts! Not to mention that our flower delivery service is excellent too, that way you don't have to go anywhere if
                        you don't want to and send flowers anywhere you want to. Deadlines are stressful enough without having to find a florist on top of it all or worse being stuck with a horribly trimmed bunch of roses because you had no time or
                        skills to arrange them yourself. Let us take some of the worries away from your busy schedule by delivering flowers for you – be it for birthdays, anniversaries, to apologize, or show thanks, any reason is a good one as long as it
                        comes from the heart!</p>

                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Luxury Flowers Perfect for any Occasion</h1>
                    <p>More is always better. Luxury flowers are perfect to convey your deep-felt love and affection for your dear ones on special days. You can make your girlfriend feel on top of the world by sending a gorgeous bouquet of red roses
                        or a mixture of other colors of roses for her on any occasion. We have a wide range of luxury flowers for delivery in Dubai, including heart-shaped rose arrangements, mesmerizing lilies, and magnificent combinations of colorful
                        roses. These luxury flowers are perfect to convey your deep-felt love and affection for your dear ones on special days. Order flowers online to celebrate love with a beautiful bouquet of roses and special colors.</p>
                    <p>Our luxury flowers in Dubai are designed to bring immense pleasure and unlimited joy on special occasions. Gifting or receiving bouquets with dazzling flowers excites your thoughts and provokes your feelings. Our luxury flowers
                        for delivery in Dubai will keep the joy and pleasure everlasting!</p>
                    <p>When you think that an exquisite bouquet can't become much bigger, Flowers.ae came up with their luxury collection of flowers for delivery in Dubai. We have the best arrangements, if you're looking for a bouquet or luxury
                        flowers, our florists can create the perfect arrangement in any size and style, from hand-tied bouquets to standing arrangements and hatboxes, we have it all. Don't put it off any longer — shop our luxurious selection, where more
                        is always better!</p>
                </div>
            </div>
            <Bottomcomp />
        </div>
    );
}

export default VipFlowers;
