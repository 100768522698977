import React from 'react';
import Header from '../components/Header';
import Bottomcomp from '../components/Bottomcomp';
import { useNavigate } from 'react-router-dom';


import image77 from '../images/mainstore/image77.png'
import image78 from '../images/mainstore/image78.png'
import image79 from '../images/mainstore/image79.png'
import image80 from '../images/mainstore/image80.png'

import image81 from '../images/mainstore/image81.png'
import image82 from '../images/mainstore/image82.png'
import image83 from '../images/mainstore/image83.png'
import image84 from '../images/mainstore/image84.png'

import image85 from '../images/mainstore/image85.png'
import image86 from '../images/mainstore/image86.png'
import image87 from '../images/mainstore/image87.png'
import image88 from '../images/mainstore/image88.png'

import image89 from '../images/mainstore/image89.png'
import image90 from '../images/mainstore/image90.png'
import image91 from '../images/mainstore/image91.png'
import image92 from '../images/mainstore/image92.png'

import image93 from '../images/mainstore/image93.png'
import image94 from '../images/mainstore/image94.png'
import image95 from '../images/mainstore/image95.png'
import image96 from '../images/mainstore/image96.png'

import image97 from '../images/mainstore/image97.png'
import image98 from '../images/mainstore/image98.png'
import image99 from '../images/mainstore/image99.png'
import image100 from '../images/mainstore/image100.png'

const texts = [
    "Birthday Pink Perfection Hatbox", "Birthday Lavender Floral Hatbox", "Birthday Vibrant Hatbox", "Birthday Perfect White Hatbox",
    "Birthday Pink Perfection - Vase", "Birthday Lavender Floral - Vase", "Birthday Vibrant - Vase", "Birthday Perfect White - Vase",
    "Birthday 50 Red Roses", "Birthday 50 Pink Roses", "Birthday 50 Yellow Roses", "Birthday 50 Tiffany Blue Roses",
    "Birthday Aurora", "Birthday Pandora", "Birthday Aurelia", "Birthday Octavia",
    "Birthday Florence Blue", "Birthday Florence Pink", "Birthday Florence White", "Birthday Grand Pink Pastel",
    "100 Pink O'hara - Grand", "150 Pink O'hara - Grand", "100 White O'hara - Grand", "150 White O'hara - Grand"
];

const images = [
    image77,
    image78,
    image79,
    image80,

    image81,
    image82,
    image83,
    image84,

    image85,
    image86,
    image87,
    image88,

    image89,
    image90,
    image91,
    image92,

    image93,
    image94,
    image95,
    image96,

    image97,
    image98,
    image99,
    image100,




];

const price = [
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>345.00</strike> 170 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 170 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 170 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 170 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>495.00</strike> 250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>495.00</strike> 250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>495.00</strike> 250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>545.00</strike> 270 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>595.00</strike> 300 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>595.00</strike> 300 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>595.00</strike> 300 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>645.00</strike> 320 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>495.00</strike> 250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>495.00</strike> 250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>495.00</strike> 250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,295.00</strike> 650 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,995.00</strike> 1,500 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,995.00</strike> 1,500 <b style={{ color: "red" }}>-50%</b></>,



];




function BirthdayFlowers() {

    const navigate = useNavigate();

    const handleViewClick = (storeIndex) => {
        navigate(`/store/${storeIndex}`);
    };


    const Store = () => (
        <div>
            <ul className="store-list">
                {[...Array(24)].map((_, index) => (
                    <li key={index} className="store">
                        <img src={images[index % images.length]} alt={`Image ${index + 1}`} onClick={() => handleViewClick(index + 80)} />
                        <p>{texts[index]}</p>
                        <p>{price[index]}</p>
                        <button onClick={() => handleViewClick(index + 80)}>View</button>
                    </li>
                ))}
            </ul>



        </div>

    );


    return (
        <div>
            <Header />
            <p style={{ marginLeft: "50px" }} className='pfromsites'><a href='/'>Home</a> <span className="dot-in-middle"></span> Birthday Flowers</p>
            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Birthday Flowers</h1>
                    <p>Celebrate every birthday in style with our exclusive collection of birthday flowers at Flowers.ae. From elegant bouquets to cheerful arrangements, our birthday flower collection is curated to make every celebration memorable. Surprise your loved ones with the perfect happy
                        birthday flowers, delivered right to their doorstep in Dubai. Let us help you make birthdays brighter with our stunning range of birthday flower bouquets and gifts.</p>
                </div>
            </div>
            <Store />

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Why Send Birthday Flowers with Flowers.ae?</h1>
                    <p>Flowers are a timeless way to express your affection and celebrate life's special moments. At Flowers.ae, we specialize in crafting and delivering the perfect birthday flower gifts that speak volumes of your love and thoughtfulness.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Can I Send Birthday Flowers on the Same Day?</h1>
                    <p>Absolutely! We pride ourselves on our expedited same-day birthday flowers delivery service. For those last-minute birthday surprises, simply place your order by 10 P.M., and we'll ensure your beautiful birthday flowers reach their
                        destination the same day.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>What Types of Birthday Flowers Can I Order?</h1>
                    <p>From luxurious bouquets to unique arrangements, our birthday collection caters to all tastes and preferences. Whether you're looking for birthday flowers for her, him, or anyone special, our range includes everything from elegant
                        roses to exotic blooms, paired perfectly with balloons or personalized gifts.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>How Do I Choose the Best Birthday Flowers?</h1>
                    <p>Selecting the right birthday flowers depends on the recipient's personality and your relationship with them. Consider their favorite colors, flowers, or even the message you want to convey. For a more detailed guide, our birthday
                        flower meaning and ideas section offers insights into making your choice truly special.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>What Makes Flowers.ae the Best Choice for Birthday Flower Delivery?</h1>
                    <p>Our commitment to quality, punctuality, and exceptional customer service sets us apart. With Flowers.ae, ordering birthday flowers online in Dubai and across the UAE is seamless and reliable. Enjoy our wide selection of birthday
                        flowers online delivery options, including birthday flowers same day delivery, to make sure your warm wishes and love are conveyed on time and in the most beautiful manner.</p>
                </div>
            </div>




            <Bottomcomp />
        </div>
    );
}

export default BirthdayFlowers;
