import React from 'react';


import phone from '../images/phone.png'
import mail from '../images/phone.png'

function GreyContainer() {
    return (
        <div className="grey-container">
            <ul>
                <li>
                    <p>We Have Your BlackUp
                        Get In touch on any of our support
                        channels</p>
                </li>
                <li>
                    <p><img src={mail} />Email</p>
                    <p>customer.service@flowers.ae</p>
                </li>
                <li>
                    <p><img src={phone} />Phone</p>
                    <p>+971 4 718 1991</p>
                </li>
            </ul>
        </div>
    );
}

export default GreyContainer;