export const MAIN_ROUTE = '/'
export const STOR_ROUTE = '/store/:storeId'
export const OUR_ROUTE = '/ourcompleterange'
export const ANIV_ROUTE = '/anniversaryflowers'
export const BIRT_ROUTE = '/birthdayflowers'
export const VIP_ROUTE = '/vipflowers'
export const SPEC_ROUTE = '/specialoffers'
export const ROSE_ROUTE = '/rosecollection'
export const TRANS_ROUTE = '/transaction/:price'
export const TRANS2_ROUTE = '/transaction2/:price'
export const CART_ROUTE = '/cart'
export const ACCEPT_ROUTE = '/succecfulltrans'
export const EROR_ROUTE = '/errortrans'
