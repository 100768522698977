import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import basket from '../images/basket.png';
import Bottomcomp from '../components/Bottomcomp';
import rate from '../images/rate.png';
import trust from '../images/trustpilot.png';
import { price, images, texts } from '../utils/waystostore';
import GreyContainer from '../components/GreyContainer';
import { CartContext } from '../components/CartContext';

function Cartpage() {
    const navigate = useNavigate();
    const { storeId } = useParams();
    const [selectedSize, setSelectedSize] = useState('Standard');
    const { addToCart } = useContext(CartContext);

    const handleSelectSize = (size) => {
        setSelectedSize(size);
    };

    const handleAddToCart = () => {
        const selectedPrice = selectedSize === 'Standard' ? price[storeId] : price[storeId] + 100;
        const product = {
            id: storeId,
            name: texts[storeId],
            price: selectedPrice,
            size: selectedSize,
            image: images[storeId],
        };
        addToCart(product);
        navigate('/cart')
    };

    function roundToNearestMultiple(num) {
        return Math.round(num / 10) * 10;
    }

    return (
        <div>
            <Header />
            <div className='cartpagediv'>
                <div className='cartpageimg'>
                    <img src={images[storeId]} alt={`Image ${storeId}`} className='big-img' />
                </div>

                <div className='cartpagetext'>
                    <h1>{texts[storeId]}</h1>
                    <p style={{ marginTop: "20px" }} className='price'> AED <strike>{price[storeId]}.00</strike> {roundToNearestMultiple(price[storeId] / 2)}.00 <b style={{ color: "red" }}>-50%</b></p>
                    <p style={{ marginTop: "20px" }}>Delight in the enchanting blend of Spray Rose Bombastic, Gerbera pink, </p>
                    <p> Eucalyptus, Wax pink, Rose Ohara pink and white, Rose Rosita pink and Pistache.</p>
                    <p>This exquisite combination of blossoms exudes elegance and sophistication, while</p>
                    <p> the gentle touch of Eucalyptus and Pistache adds a natural allure. Elevate any</p>
                    <p>space with the vibrant hues and captivating beauty of this opulent floral ensemble</p>

                    <p>Gift Set options include:</p>
                    <p>* with Balloons 3pcs</p>
                    <p>* with Box of Chocolates 8pcs & 3 Balloons</p>
                    <p>* with Nutella Ganache Cake & 6 Balloons</p>

                    <p style={{ marginTop: "20px" }}><b>Size:</b></p>

                    <div className='sizecontainer'>
                        <div className={`sizecontainersub ${selectedSize === 'Standard' ? 'selected' : ''}`} onClick={() => handleSelectSize('Standard')}>
                            <p>Standard with</p>
                            <p>Free Upgrade to</p>
                            <p>Large ({roundToNearestMultiple(price[storeId] / 2)} AED)</p>
                        </div>

                        <div className={`sizecontainersub ${selectedSize === 'Large' ? 'selected' : ''}`} onClick={() => handleSelectSize('Large')}>
                            <p>Large with Free</p>
                            <p>Upgrade to Extra </p>
                            <p>Large ({roundToNearestMultiple(price[storeId] / 2) + 100} AED)</p>
                        </div>
                    </div>

                    <button className="buttonWithImage" onClick={handleAddToCart}>
                        <img src={basket} alt="Basket" />
                        <span className="buttonText">Add to cart</span>
                    </button>

                    <div className="centered-box">
                        <p>
                            Excellent <img src={rate} alt="Rating" /> 9,840 reviews on <img src={trust} alt="TrustPilot" />
                        </p>
                    </div>
                </div>
            </div>

            <GreyContainer />
            <Bottomcomp />
        </div>
    );
}

export default Cartpage;
