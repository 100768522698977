import React from 'react';

import Header from '../../components/Header';
import Bottomcomp from '../../components/Bottomcomp';
import GreyContainer from '../../components/GreyContainer';

import accept from '../../images/accept.png'
const SuccesfulTrans = () => {

    return (
        <div>
            <Header />
            <div className='div-eror'>
                <div>
                    <p><img src={accept} />The payment has been confirmed, and we are preparing for delivery. </p>
                    <p>Please expect it at the provided address. </p>




                </div>
            </div>

            <GreyContainer />
            <Bottomcomp />
        </div>
    );
};

export default SuccesfulTrans;
