import React from 'react';
import Header from '../components/Header';
import Bottomcomp from '../components/Bottomcomp';
import { useNavigate } from 'react-router-dom';


import image49 from '../images/mainstore/image49.png'
import image50 from '../images/mainstore/image50.png'
import image51 from '../images/mainstore/image51.png'
import image52 from '../images/mainstore/image52.png'

import image53 from '../images/mainstore/image53.png'
import image54 from '../images/mainstore/image54.png'
import image55 from '../images/mainstore/image55.png'
import image56 from '../images/mainstore/image56.png'

import image57 from '../images/mainstore/image57.png'
import image58 from '../images/mainstore/image58.png'
import image59 from '../images/mainstore/image59.png'
import image60 from '../images/mainstore/image60.png'

import image61 from '../images/mainstore/image61.png'
import image62 from '../images/mainstore/image62.png'
import image63 from '../images/mainstore/image63.png'
import image64 from '../images/mainstore/image64.png'

import image65 from '../images/mainstore/image65.png'
import image66 from '../images/mainstore/image66.png'
import image67 from '../images/mainstore/image67.png'
import image68 from '../images/mainstore/image68.png'

import image69 from '../images/mainstore/image69.png'
import image70 from '../images/mainstore/image70.png'
import image71 from '../images/mainstore/image71.png'
import image72 from '../images/mainstore/image72.png'

import image73 from '../images/mainstore/image73.png'
import image74 from '../images/mainstore/image74.png'
import image75 from '../images/mainstore/image75.png'
import image76 from '../images/mainstore/image76.png'

const texts = [
    "Anniversary Red Heart", "Anniversary Pink Heart", "Anniversary White Heart", "Anniversary Tiffany Blue Heart",
    "Anniversary 25 Pink Roses", "Anniversary 25 Red Roses", "Anniversary 50 Pink Roses", "Anniversary 50 Red Roses",
    "Anniversary Aurora", "Anniversary Rosalba", "Anniversary 100 Red Roses", "Anniversary 100 Pink Roses",
    "Anniversary 50 Pink Roses - Vase", "Anniversary 100 Pink Roses - Vase", "Anniversary 50 Red Roses - Vase", "Anniversary 100 Red Roses - Vase",
    "100 Pink O'hara - Grand", "100 White O'hara - Grand", "150 Pink O'hara - Grand", "150 White O'hara - Grand",
    "Anniversary 365 Pink Roses - Box", "Anniversary Arianna", "Anniversary Violet", "Anniversary 500 Red Roses - XXL",
    "Coraline Grand", "Adelle Grand", "Violet Grand", "Bianca Grand"
];

const images = [
    image49,
    image50,
    image51,
    image52,

    image53,
    image54,
    image55,
    image56,

    image57,
    image58,
    image59,
    image60,

    image61,
    image62,
    image63,
    image64,

    image65,
    image66,
    image67,
    image68,

    image69,
    image70,
    image71,
    image72,

    image73,
    image74,
    image75,
    image76,

];

const price = [
    <>from AED <strike>395.00</strike> 200 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>395.00</strike> 200 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>395.00</strike> 200 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>450.00</strike> 225 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>350.00</strike> 175 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>350.00</strike> 175 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>495.00</strike> 250 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>495.00</strike> 250 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>595.00</strike> 300 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>595.00</strike> 300 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>995.00</strike> 500 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>995.00</strike> 500 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>745.00</strike> 370 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,345.00</strike> 650 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>745.00</strike> 370 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,395.00</strike> 700 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,995.00</strike> 1,500 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,995.00</strike> 1,500 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>3,995.00</strike> 2,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>1,995.00</strike> 1,000 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>5,495.00</strike> 2,700 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>2,195.00</strike> 1,100 <b style={{ color: "red" }}>-50%</b></>,
];




function AnniversaryFlowers() {

    const navigate = useNavigate();

    const handleViewClick = (storeIndex) => {
        navigate(`/store/${storeIndex}`);
    };


    const Store = () => (
        <div>
            <ul className="store-list">
                {[...Array(28)].map((_, index) => (
                    <li key={index} className="store">
                        <img src={images[index % images.length]} alt={`Image ${index + 1}`} onClick={() => handleViewClick(index + 52)} />
                        <p>{texts[index]}</p>
                        <p>{price[index]}</p>
                        <button onClick={() => handleViewClick(index + 52)}>View</button>
                    </li>
                ))}
            </ul>



        </div>

    );


    return (
        <div>
            <Header />
            <p style={{ marginLeft: "50px" }} className='pfromsites'><a href='/'>Home</a> <span className="dot-in-middle"></span> Anniversary Flowers</p>
            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Anniversary Flowers</h1>
                    <p>Celebrate love and commitment with our exquisite collection of anniversary flowers at Flowers.ae. Each arrangement is meticulously crafted to symbolize the beauty and growth of your relationship. Make your anniversary memorable with flowers that speak volumes of your
                        affection and devotion. Perfect for every milestone, our anniversary flowers are a testament to enduring love.</p>
                </div>
            </div>
            <Store />
            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Anniversary Flower Delivery in Dubai</h1>
                    <p>Anniversary flowers are more than just a gift; they are a symbol of enduring love and the beautiful journey shared between two people. Each bloom and color carries a unique meaning, making anniversary flowers a deeply
                        personal and significant way to express your love and celebrate another year together.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>What do Anniversary Flowers Mean?</h1>
                    <p>Understanding the meaning behind flowers can add a layer of depth to your gift. For example, red roses symbolize love and passion, while lilies represent purity and renewal. Including flowers with meanings relevant to your
                        relationship can make your anniversary gift even more special and meaningful.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>How Can I Ensure My Anniversary Flowers Are Delivered Fresh?</h1>
                    <p>At Flowers.ae, freshness is a promise. With expedited same-day delivery on orders placed by 10 P.M., we ensure your flowers arrive fresh and vibrant. Our commitment to quality means your anniversary flowers are hand-selected
                        and arranged with care, making them a joy to receive and behold.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Choosing the Best Flowers for Anniversary</h1>
                    <p>Selecting the best flowers for your anniversary is about understanding your partner’s preferences and the message you want to convey. Classic choices like roses and lilies are always in vogue, but don't hesitate to explore unique
                        options like orchids or peonies for something a bit different. Consider the milestone you're celebrating and select flowers that reflect the journey you've shared.</p>
                </div>
            </div>

            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Anniversary Flowers Delivery: How Does It Work?</h1>
                    <p>Sending anniversary flowers with Flowers.ae is effortless. Simply choose your favorite arrangement from our collection, complete your purchase, and we’ll handle the rest. With our reliable delivery service, you can rest assured your
                        gesture of love will arrive on time and as beautiful as when it left our shop, marking your special day with elegance and affection.</p>
                </div>
            </div>





            <Bottomcomp />
        </div>
    );
}

export default AnniversaryFlowers;
