
import logo from "../images/black-logo.svg";


const BlackUpTrans = () => (
    <div>
        <div className='headerdiv'>
            <ul className='headerlist'>
                <li style={{ marginRight: "10%" }}>Outstanding Service</li>
                <li>Free Delivery to all Emirates</li>
                <li style={{ marginLeft: "10%" }}>Video Approval on all orders</li>
            </ul>
        </div>

        <div className="transaction">
            <div className="transaction-logo">
                <a href='/'><img src={logo} alt="Logo" /></a>
            </div>
        </div>
        <div className="gray-line"></div>
    </div>
);


export default BlackUpTrans;
