import React from 'react';
import Header from '../components/Header';
import Bottomcomp from '../components/Bottomcomp';
import { useNavigate } from 'react-router-dom';

import image1 from '../images/mainstore/image1.png'
import image2 from '../images/mainstore/image2.webp'
import image3 from '../images/mainstore/image3.png'
import image4 from '../images/mainstore/image4.png'

import image25 from '../images/mainstore/image25.png'
import image26 from '../images/mainstore/image26.png'
import image27 from '../images/mainstore/image27.png'
import image28 from '../images/mainstore/image28.png'

import image29 from '../images/mainstore/image29.png'
import image30 from '../images/mainstore/image30.png'
import image31 from '../images/mainstore/image31.png'
import image32 from '../images/mainstore/image32.png'

import image33 from '../images/mainstore/image33.png'
import image34 from '../images/mainstore/image34.png'
import image35 from '../images/mainstore/image35.png'
import image36 from '../images/mainstore/image36.png'

import image37 from '../images/mainstore/image37.png'
import image38 from '../images/mainstore/image38.png'
import image39 from '../images/mainstore/image39.png'
import image40 from '../images/mainstore/image40.png'

import image41 from '../images/mainstore/image41.png'
import image42 from '../images/mainstore/image42.png'
import image43 from '../images/mainstore/image43.png'
import image44 from '../images/mainstore/image44.png'

import image45 from '../images/mainstore/image45.png'
import image46 from '../images/mainstore/image46.png'
import image47 from '../images/mainstore/image47.png'
import image48 from '../images/mainstore/image48.png'


const texts = [
    "Red Roses with greens", "White Roses with greens", "Pink Roses with greens", "Purple Roses with greens",
    "Lavender Floral Hatbox", "Pink Perfection Hatbox", "Vibrant Hatbox", "Perfect White Hatbox",
    "Petite Misty Bubbles Hatbox", "Petite Adelle Hatbox", "Petite Red Roses - Hatbox", "Petite Pink Roses - Hatbox",
    "Petite White Roses - Hatbox", "Petite Yellow Roses - Hatbox", "Petite Purple Roses - Hatbox", "Petite Tiffany Blue Roses - Hatbox",
    "Red Roses - Hatbox", "White Roses - Hatbox", "Pink Roses - Hatbox", "Tiffany Blue Roses - Hatbox",
    "3 Purple Orchids", "White Orchid", "2 White Orchids", "3 White Orchids",
    "Perfect White", "Lavender Floral", "Pink Perfection", "Vibrant"
];

const images = [
    image25,
    image26,
    image27,
    image28,

    image1,
    image2,
    image3,
    image4,

    image29,
    image30,
    image31,
    image32,

    image33,
    image34,
    image35,
    image36,

    image37,
    image38,
    image39,
    image40,

    image41,
    image42,
    image43,
    image44,

    image45,
    image46,
    image47,
    image48,
];

const price = [
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>265.00</strike> 130 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>265.00</strike> 130 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>245.00</strike> 120 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 175 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 175 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 175 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>345.00</strike> 200 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>745.00</strike> 375 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>, 
    <>from AED <strike>525.00</strike> 260 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>745.00</strike> 375 <b style={{ color: "red" }}>-50%</b></>,

    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>, 
    <>from AED <strike>325.00</strike> 160 <b style={{ color: "red" }}>-50%</b></>,
];

const SoMuchtext = () => (
    <div>
        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Our Complete Range: Exquisite Flower Arrangements for Every Occasion</h1>
                <p>Flowers.ae is the top flower shop in Dubai that offers the best flower delivery service across the UAE. Presenting our complete range of stunning floral arrangements that are designed to add beauty, elegance, and joy to your
                    special moments. Whether you are celebrating a birthday, anniversary, or wedding, or simply want to brighten someone's day, our diverse collection of hatboxes, pots, vases, hand-tied bouquets, and more will leave a lasting
                    impression.</p>
            </div>
        </div>

        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Hatbox Arrangements</h1>
                <p>Step away from traditional floral presentations and embrace the charm of our hatbox arrangements. These stunning floral gifts are designed to captivate with their unique elegance. Crafted using the finest blooms, each
                    arrangement is thoughtfully arranged in stylish boxes. Present your loved ones with this chic and innovative gift that will surely leave them speechless. Get these unique and elegant floral gifts from Flowers.ae.</p>
            </div>
        </div>

        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Pot and Vase Arrangements</h1>
                <p>For a classic and timeless touch, explore our pot and vase arrangements. These carefully curated floral compositions are thoughtfully arranged in beautiful pots and vases, enhancing the natural beauty of each bloom. Choose
                    from our range of pot and vase options to complement any interior and create a captivating centerpiece for your home or event.</p>
            </div>
        </div>

        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Hand-Tied Bouquets</h1>
                <p>There is nothing quite as enchanting as a hand-tied bouquet. Our skilled florists meticulously select the freshest blooms and artistically tie them together to create breathtaking bouquets. Whether you are looking for a romantic
                    bouquet of red roses, a vibrant mix of colorful flowers, or a delicate arrangement of scented lilies, our hand-tied bouquets are sure to impress.</p>
            </div>
        </div>

        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Bouquets</h1>
                <p>A bouquet is a classic choice that never goes out of style. Our selection includes an array of stunning options, from a simple yet elegant  50 roses bouquet to an extravagant display of 200 roses. With an assortment of colors and
                    flower varieties available, you can customize your bouquet to perfectly match the recipient's taste and preferences.</p>
            </div>
        </div>

        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Scented Lilies</h1>
                <p>Add a touch of luxury and elegance to any occasion with our scented lilies. These exquisite flowers not only boast captivating beauty but also fill the air with a delightful fragrance. Whether you choose a bouquet or an
                    arrangement, our scented lilies are sure to create a memorable sensory experience.</p>
            </div>
        </div>

        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Vibrant Stand Arrangements</h1>
                <p>Make a bold statement with our vibrant stand arrangements. These striking floral compositions are expertly designed to create a breathtaking visual impact. Perfect for grand events, corporate functions, or even as a luxurious
                    gift, our stand arrangements are sure to leave a lasting impression.</p>
            </div>
        </div>


        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Letter Box, Heartbox, and Word Box Arrangements</h1>
                <p>Looking for a unique and personalized way to send a message? Our letterbox, heart box, and word box arrangements allow you to express your sentiments through the language of flowers. Choose from a variety of pre-designed
                    messages or create a customized layout that conveys your thoughts and emotions.</p>
            </div>
        </div>

        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Orchids</h1>
                <p>Orchids are synonymous with grace, beauty, and longevity. These delicate flowers make a sophisticated choice for any occasion. Whether you opt for a potted orchid or an arrangement featuring these exquisite blooms, you can
                    be sure that your gift will be cherished long after the occasion has passed.</p>
            </div>
        </div>

        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Luxury Flowers</h1>
                <p>For truly extraordinary moments, indulge in our collection of luxury flowers. These exquisite blooms are carefully selected for their rarity, uniqueness, and unparalleled beauty. From rare blue roses to opulent gold roses, our luxury
                    flowers are meant to leave a lasting impression on the recipient.</p>
            </div>
        </div>
        <div className="ourcomptext-div">
            <div className="ourcomptext-block">
                <h1>Additional Gifts to Enhance the Experience</h1>
                <p>To make your floral gift even more memorable, we offer a range of additional gifts to complement the beauty of the flowers. Choose from our selection of teddy bears, delectable cakes, vibrant balloons, or elegant candles to add
                    that extra touch of love and celebration.</p>

                <p>Flowers.ae is responsible for providing you with the finest floral arrangements that reflect your emotions and create unforgettable moments. With our reliable flower delivery service across the UAE, you can trust us to bring
                    beauty and joy to your doorstep or the door of your loved ones. Contact us today and explore our complete range. Let us help you make every occasion special.</p>
            </div>
        </div>

    </div>

);


function OurCompleteRange() {

    const navigate = useNavigate();

    const handleViewClick = (storeIndex) => {
        navigate(`/store/${storeIndex}`);
    };


    const Store = () => (
        <div>
            <ul className="store-list">
                {[...Array(28)].map((_, index) => (
                    <li key={index} className="store">
                        <img src={images[index % images.length]} alt={`Image ${index + 1}`} onClick={() => handleViewClick(index + 24)} />
                        <p>{texts[index]}</p>
                        <p>{price[index]}</p>
                        <button onClick={() => handleViewClick(index + 24)}>View</button>
                    </li>
                ))}
            </ul>



        </div>

    );


    return (
        <div>
            <Header />
            <p style={{ marginLeft: "50px" }} className='pfromsites'><a href='/'>Home</a> <span className="dot-in-middle"></span> Our Complete Range</p>
            <div className="ourcomptext-div">
                <div className="ourcomptext-block">
                    <h1>Our Complete Range</h1>
                    <p>Our florists have a complete range of flowers for all occasions. From special occasions such as birthdays, anniversaries, graduations, and more, to everyday occasions such as Just Because, Get Well, and Sympathy, we have the perfect bouquet of flowers for every occasion.</p>
                </div>
            </div>
            <Store />
            <SoMuchtext />
            <Bottomcomp />
        </div>
    );
}

export default OurCompleteRange;
