import AnniversaryFlowers from "./pages/AnniversaryFlowers"
import BirthdayFlowers from "./pages/BirthdayFlowers"
import Cart from "./pages/Cart"
import Cartpage from "./pages/Cartpage"
import Main from "./pages/Main/Main"
import OurCompleteRange from "./pages/OurCompleteRange"
import RoseCollection from "./pages/RoseCollection"
import SpecialOffers from "./pages/SpecialOffers"
import Transaction from "./pages/Transaction"
import Transaction2 from "./pages/Transaction2"
import ErrorTrans from "./pages/Transfer/ErrorTrans"
import SuccesfulTrans from "./pages/Transfer/SuccesfulTrans"
import VipFlowers from "./pages/VipFlowers"

import { ANIV_ROUTE, BIRT_ROUTE, MAIN_ROUTE, OUR_ROUTE, STOR_ROUTE, VIP_ROUTE, SPEC_ROUTE, ROSE_ROUTE, TRANS_ROUTE, TRANS2_ROUTE, CART_ROUTE, EROR_ROUTE, ACCEPT_ROUTE } from "./utils/consts"


export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        Component: Main

    },
    {
        path: STOR_ROUTE,
        Component: Cartpage

    },
    {
        path: OUR_ROUTE,
        Component: OurCompleteRange

    },

    {
        path: ANIV_ROUTE,
        Component: AnniversaryFlowers

    },

    {
        path: BIRT_ROUTE,
        Component: BirthdayFlowers

    },

    {
        path: VIP_ROUTE,
        Component: VipFlowers

    },

    {
        path: SPEC_ROUTE,
        Component: SpecialOffers
    },


    {
        path: ROSE_ROUTE,
        Component: RoseCollection
    },

    {
        path: TRANS_ROUTE,
        Component: Transaction
    },

    {
        path: TRANS2_ROUTE,
        Component: Transaction2
    },
    {
        path: CART_ROUTE,
        Component: Cart
    },

    {
        path: EROR_ROUTE,
        Component: ErrorTrans
    },
    {
        path: ACCEPT_ROUTE,
        Component: SuccesfulTrans
    },
    
]